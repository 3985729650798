import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Button,
  TextField,
  Box,
  List,
  ListItem,
  Select,
  MenuItem,
} from "@material-ui/core";
import CaserCard from "../../components/mainComponents/card";
import LeftMenuLayout from "../../components/left-menu-detail";

import FeathersContext from "../../feathersContext";
export default class Churches extends React.Component {
  static contextType = FeathersContext;
  state = {
    churches: [],
    activeChurch: null,
    fathers: [],
  };
  async componentDidMount() {
    console.log(this.props.match.url.split("/"));
    console.log(this.props.history);
    console.log(this.props.location);
    this.props.match.params.churchId = "ggg";
    this.setState({
      activeChurch: this.props.match.url.split("/")[3] || null,
    });
    await this.getChurches();
    await this.getFathers();
    this.context.app.service("churches").on("patched", this.updateOnPatched);
    this.context.app.service("churches").on("removed", this.updateOnRemoved);
  }
  updateOnPatched = (message) =>
    this.setState((state) => {
      state.churches[this.getChurchIndex(message._id)] = message;
      return state;
    });

  updateOnRemoved = (message) =>
    this.setState((state) => {
      let index = this.getChurchIndex(message._id);
      state.churches.splice(index, 1);
      let id = null;
      if (state.churches[index]) id = state.churches[index]._id;
      else if (state.churches[index - 1]) id = state.churches[index - 1]._id;
      else if (state.churches[index + 1]) id = state.churches[index + 1]._id;
      this.setActiveChurch(id);
      return state;
    });

  async componentWillUnmount() {
    this.context.app.service("churches").off("patched", this.updateOnPatched);
    this.context.app.service("churches").off("removed", this.updateOnRemoved);
  }
  async getChurches() {
    try {
      let list = await this.context.app.service("churches").find();
      list.data.sort((a, b) => {
        if (
          a.churchName + a.churchCity + a.churchState <
          b.churchName + b.churchCity + b.churchState
        ) {
          return -1;
        }
        if (
          a.churchName + a.churchCity + a.churchState >
          b.churchName + b.churchCity + b.churchState
        ) {
          return 1;
        }
        return 0;
      });
      this.setState({ churches: list.data });
      if (this.state.churches.length && this.state.activeChurch === null)
        this.setActiveChurch(this.state.churches[0]._id);
    } catch (e) {}
  }
  async getFathers() {
    try {
      let list = await this.context.app.service("fathers").find();
      list.data.sort((a, b) => {
        if (a.firstName + a.lastName < b.firstName + b.lastName) {
          return -1;
        }
        if (a.firstName + a.lastName > b.firstName + b.lastName) {
          return 1;
        }
        return 0;
      });
      this.setState({ fathers: list.data });
      if (this.state.fathers.length && this.state.activeFather === null)
        this.setActiveFather(this.state.fathers[0]._id);
      await this.getChurches();
    } catch (e) {}
  }
  async addChurch() {
    try {
      let newChurch = await this.context.app.service("churches").create({});
      await this.getChurches();
      this.setActiveChurch(newChurch._id);
    } catch (e) {}
  }
  setActiveChurch(id) {
    this.setState({ activeChurch: id });
    let url = this.props.match.url.split("/");
    url[3] = id;
    this.props.history.replace(url.join("/"));
  }
  getChurchIndex(id = this.state.activeChurch) {
    for (let [index, church] of this.state.churches.entries()) {
      if (church._id === id) {
        return index;
      }
    }
    return -1;
  }

  render() {
    // if (this.state.churches.length === 0)
    //   return (
    //     <Backdrop open>
    //       <CircularProgress />
    //     </Backdrop>
    //   );
    return (
      <LeftMenuLayout
        leftDrawer={
          <div
            style={{
              height: "inherit",
              background: "#ECEFF1",
              overflowY: "scroll",
            }}
          >
            <List>
              {this.state.churches.map((v) => (
                <ListItem
                  button
                  style={{
                    fontWeight: 600,
                    padding: "15px 10px",
                    background:
                      this.state.activeChurch === v._id ? "#B0BEC5" : "",
                  }}
                  onClick={() => this.setActiveChurch(v._id)}
                  key={v._id}
                >
                  {v.churchName}
                  <br /> &nbsp;&nbsp;
                  {v.churchCity}, {v.churchState}
                </ListItem>
              ))}
            </List>
          </div>
        }
        mainContent={(anchor) => (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 0,
                background: "#546E7A",
                color: "#fff",
                padding: 10,
              }}
            >
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                {anchor} Churches
              </Typography>
              <Button
                color="inherit"
                onClick={this.addChurch.bind(this)}
                style={{ flexGrow: 0, fontWeight: "bold" }}
              >
                New Church
              </Button>
            </div>
            <div
              style={{
                flexGrow: 3,
                background: "#CFD8DC",
                overflowY: "scroll",
                paddingBottom: 30,
              }}
            >
              <ChurchDetails parent={this} id={this.state.activeChurch} />
            </div>
          </>
        )}
      />
    );
  }
}

class ChurchDetails extends React.Component {
  static contextType = FeathersContext;

  state = {
    data: null,
    fathers: [],
    fathersIds: [],
    deleteDialogOpen: false,
  };
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getChurch();
    }
  }
  async getChurch() {
    if (this.props.id === null) {
      return;
    }
    this.setState({ data: null });
    try {
      let data = await this.context.app.service("churches").get(this.props.id);
      this.setState({ data });
      await this.getChurchFathers();
    } catch (e) {
      console.log(e);
    }
  }
  async addFather() {
    try {
      await this.context.app
        .service("fathers")
        .create({ churchIds: [this.props.id] });
      await this.getChurchFathers();
    } catch (e) {}
  }
  async assignFathers() {
    try {
      let fathersIds = this.state.fathersIds;
      let oldIds = this.state.fathers.map((v) => v._id);
      console.log(fathersIds, oldIds);
      await this.getChurchFathers(false);
      for (let oldId of oldIds) {
        if (!fathersIds.includes(oldId)) {
          let currentChurch = this.state.fathers.filter(
            (v) => v._id === oldId
          )[0];
          if (currentChurch.churchIds.includes(this.props.id)) {
            currentChurch.churchIds.splice(
              currentChurch.churchIds.indexOf(this.props.id),
              1
            );
            await this.context.app.service("fathers").patch(oldId, {
              churchIds: currentChurch.churchIds,
            });
          }
        }
      }
      for (let newId of fathersIds) {
        if (!oldIds.includes(newId)) {
          let currentChurch = await this.context.app
            .service("fathers")
            .get(newId);
          if (!currentChurch.churchIds.includes(this.props.id)) {
            currentChurch.churchIds.push(this.props.id);
            await this.context.app.service("fathers").patch(newId, {
              churchIds: currentChurch.churchIds,
            });
          }
        }

        // console.log(
        //     await this.context.app
        //         .service("fathers")
        //         .update(newId, {
        //             func: "addChurch",
        //             churchId: this.props.id,
        //         })
        // );
      }
      await this.getChurchFathers();
    } catch (e) {
      console.log(e);
    }
  }
  async getChurchFathers(includeIDs = true) {
    if (this.props.id === null) {
      return;
    }
    this.setState({ fathers: [] });
    try {
      let fathers = await this.context.app
        .service("fathers")
        .find({ query: { churchIds: this.props.id } });
      this.setState({
        fathers: fathers.data,
        fathersIds: includeIDs
          ? fathers.data.map((v) => v._id)
          : this.state.fathersIds,
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateField(fieldName, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      let data = {
        // _id: this.state.data._id,
        [fieldName]: value,
      };
      this.setState({ data: Object.assign(this.state.data, data) });
      data[fieldName] = data[fieldName].trim();
      this.updateFieldServer(this.state.data._id, data);
    } catch (e) {
      console.log(e);
    }
  }
  async updateFieldServer(id, data) {
    await this.context.app.service("churches").patch(id, data);
  }
  async removeChurch() {
    if (this.props.id === null) {
      return;
    }
    try {
      await this.context.app.service("churches").remove(this.props.id);

      this.setState({ deleteDialogOpen: false });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    if (this.props.id === null)
      return <div>Select a Church to edit the details.</div>;
    else if (this.state.data === null) {
      return null;
    } else {
      return (
        <>
          <CaserCard
            header={"Church Details"}
            footer={
              <Box display="flex" style={{ alignItems: "center" }}>
                <Typography variant="caption">
                  Database ID: {this.state.data._id}
                </Typography>
                <Box flexGrow={1} />
                <Button
                  color="secondary"
                  onClick={() =>
                    this.setState({
                      deleteDialogOpen: true,
                    })
                  }
                >
                  Delete
                </Button>
              </Box>
            }
          >
            <div style={{ textAlign: "center" }}>
              <TextField
                fullWidth
                label="Church Name"
                value={this.state.data.churchName}
                onChange={(e) => {
                  this.updateField("churchName", e.target.value);
                }}
              />
              <br />
              <TextField
                fullWidth
                label="City"
                value={this.state.data.churchCity}
                onChange={(e) => {
                  this.updateField("churchCity", e.target.value);
                }}
              />
              <br />
              <TextField
                fullWidth
                label="State"
                value={this.state.data.churchState}
                onChange={(e) => {
                  this.updateField("churchState", e.target.value);
                }}
              />
              <TextField
                fullWidth
                label="Church Code"
                value={this.state.data.churchCode}
                onChange={(e) => {
                  this.updateField("churchCode", e.target.value);
                }}
              />
            </div>
          </CaserCard>
          <CaserCard
            header={"Church Fathers"}
            footer={
              <Box display="flex">
                <Select
                  color="primary"
                  variant={"outlined"}
                  size="small"
                  margin="none"
                  multiple
                  value={this.state.fathersIds || []}
                  onChange={(e) => {
                    this.setState({
                      fathersIds: e.target.value,
                    });
                  }}
                  onClose={(e) => {
                    this.assignFathers(e.target.value);
                  }}
                  displayEmpty
                  renderValue={(v) => {
                    return "Assign Existing Fathers";
                  }}
                >
                  {this.props.parent.state.fathers.map((v) => (
                    <MenuItem key={v._id} value={v._id}>
                      Fr. {v.firstName} {v.lastName}
                    </MenuItem>
                  ))}
                  )
                </Select>
                <Box flexGrow={1} />
                <Button color="primary" onClick={this.addFather.bind(this)}>
                  Create New Father
                </Button>
              </Box>
            }
          >
            <div style={{ textAlign: "center" }}>
              {this.state.fathers.map((v) => (
                <FatherDetails parent={this} id={v._id} key={v._id} />
              ))}
            </div>
          </CaserCard>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            open={this.state.deleteDialogOpen}
          >
            <DialogTitle id="confirmation-dialog-title">
              Are you sure?
            </DialogTitle>
            <DialogContent>
              Are you sure you want to permenantly delete this church from the
              database?
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => this.setState({ deleteDialogOpen: false })}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={this.removeChurch.bind(this)} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }
}

class FatherDetails extends React.Component {
  static contextType = FeathersContext;

  state = { data: null, deleteDialogOpen: false, moveDialogOpen: false };
  componentDidMount() {
    this.getFather();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getFather();
    }
  }
  async getFather() {
    if (this.props.id === null) {
      return;
    }
    this.setState({ data: null });
    try {
      let data = await this.context.app.service("fathers").get(this.props.id);

      this.setState({ data });
    } catch (e) {
      console.log(e);
    }
  }
  async updateField(fieldName, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      let data = {
        // _id: this.state.data._id,
        [fieldName]: value,
      };
      this.setState({ data: Object.assign(this.state.data, data) });
      data[fieldName] = data[fieldName].trim();
      await this.context.app
        .service("fathers")
        .patch(this.state.data._id, data);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    if (this.props.id === null) return null;
    else if (this.state.data === null) {
      return <div></div>;
    } else {
      return (
        <CaserCard
          header={
            <Box display="flex">
              Fr. {this.state.data.firstName} {this.state.data.lastName}
              <Box flexGrow={1} />
              <Button
                href={"/admin/fathers/" + this.props.id}
                variant="contained"
                size="small"
              >
                Go
              </Button>
            </Box>
          }
        >
          {/* <Box textAlign="center">
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Fr.</InputAdornment>
                ),
              }}
              label="First Name"
              prefix={"Fr."}
              value={this.state.data.firstName}
              onChange={(e) => {
                this.updateField("firstName", e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="Last Name"
              prefix={"Fr."}
              value={this.state.data.lastName}
              onChange={(e) => {
                this.updateField("lastName", e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              value={this.state.data.emailAddress}
              onChange={(e) => {
                this.updateField("emailAddress", e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="Phone Number"
              type="phone"
              value={this.state.data.phoneNumber}
              onChange={(e) => {
                this.updateField("phoneNumber", e.target.value);
              }}
            />
          </Box> */}
        </CaserCard>
      );
    }
  }
}
