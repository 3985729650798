import React from "react";
import Hammer from "hammerjs";
import { withResizeDetector } from "react-resize-detector";

let animationDuration = 0.2;

class WebSwipe extends React.Component {
  slideGo = false;
  slideLocation = 0;
  scrollingTimeoutl;
  isScrolling = false;
  container = React.createRef();
  left = React.createRef();
  right = React.createRef();
  center = React.createRef();
  state = {
    leftWidth: this.props.leftWidth || 400,
    rightWidth: this.props.rightWidth || 400,
    containerWidth: this.props.width,
    leftActive: this.props.leftView ? true : false,
    rightActive: this.props.rightView ? true : false,
    leftSticky: false,
    rightSticky: false,
    leftRange: this.props.leftWidth || 400,
    rightRange: this.props.rightWidth || 400,
    swipingActive: false,
    menuState: 0,
    animate: true,
  };
  componentDidMount() {
    this.calculateWidths(true);
    // console.log(this.props.allowSwipe);
    if (this.props.allowSwipe !== false) this.setGestures();

    // this.center.current.addEventListener(
    //   "scroll",
    //   (event) => {
    //     if (this.isScrolling === false) console.log("Scrolling has started.");
    //     this.isScrolling = true;
    //     window.clearTimeout(this.scrollingTimeoutl);
    //     this.scrollingTimeoutl = setTimeout(() => {
    //       this.isScrolling = false;
    //       console.log("Scrolling has stopped.");
    //     }, 66);
    //   },
    //   true
    // );
  }
  componentDidUpdate(prevProps) {
    const { width } = this.props;
    this.calculateWidths();
    if (width !== prevProps.width) {
      this.setState({
        containerWidth: width,
      });
    }
    if (this.props.state !== prevProps.state) {
      this.setState({ menuState: this.props.state });
      this.restoreToMenuState();
    }
  }
  componentWillUnmount() {}
  calculateWidths(force = false) {
    if (force === false && this.props.width === this.state.containerWidth)
      return;
    this.setState((state) => {
      state.containerWidth = this.props.width;
      state.leftWidth = this.props.leftWidth || 400;
      state.rightWidth = this.props.rightWidth || 400;
      //Adjust Sidebar Width For Screen Size
      if (state.containerWidth * 0.8 < state.leftWidth)
        state.leftWidth = state.containerWidth * 0.8;
      if (state.containerWidth * 0.8 < state.rightWidth)
        state.rightWidth = state.containerWidth * 0.8;
      //disable Sides
      if (state.leftActive === false) {
        state.leftWidth = 0;
      }
      if (state.rightActive === false) {
        state.rightWidth = 0;
      }

      //sticky
      if (
        this.props.leftStickyMinWidth &&
        state.containerWidth >= this.props.leftStickyMinWidth
      ) {
        state.leftSticky = true;
        state.leftRange = 0;
      } else {
        state.leftSticky = false;
        state.leftRange = state.leftWidth;
      }
      if (
        this.props.rightStickyMinWidth &&
        state.containerWidth >= this.props.rightStickyMinWidth
      ) {
        state.rightSticky = true;
        state.rightRange = 0;
      } else {
        state.rightSticky = false;
        state.rightRange = state.rightWidth;
      }
      //   console.log(state.leftSticky, state.rightSticky);
      return state;
    });
    if (this.state.menuState === 0) this.hideMenu();
    else if (this.state.menuState === -1) this.showLeftMenu();
    else if (this.state.menuState === 1) this.showRightMenu();
  }
  isHorizontalAngle(angle) {
    return angle > 0;
  }
  setGestures() {
    // console.log(this.state);
    this.Pager = new Hammer(this.center.current, {
      transform: false,
      recognizers: [
        [
          Hammer.Pan,
          {
            event: "pan",
            direction: 6,
            threshold: 30,
          },
        ],
      ],
    });
    this.Pager.on("panmove", (ev) => {
      if (this.props.swipeDisabled || this.isScrolling) {
        this.restoreToMenuState();
        return;
      }
      if (!this.slideGo) {
        this.slideGo = true;
        this.setState({ animate: false, swipingActive: true });

        this.slideLocation = this.getCenterPosition();
        if (ev.direction === 2 || ev.direction === 4) {
          this.slideGo = true;
        } else {
          this.slideGo = false;
        }
        this.offSetDistance = ev.deltaX;
      }
      ev.preventDefault();
      ev.deltaX = 1.1 * (ev.deltaX - this.offSetDistance);
      if (
        parseInt(ev.deltaX + this.slideLocation, 10) < this.state.leftRange &&
        parseInt(ev.deltaX + this.slideLocation, 10) > -this.state.rightRange
      ) {
        this.setTranslation(ev.deltaX + this.slideLocation);
      } else if (
        parseInt(ev.deltaX + this.slideLocation, 10) > this.state.leftRange
      ) {
        this.setTranslation(this.state.leftRange);
      } else if (
        parseInt(ev.deltaX + this.slideLocation, 10) < this.state.rightRange
      ) {
        this.setTranslation(0 - this.state.rightRange);
      }
    });

    // this.Pager.on("panstart", (ev) => {
    //   // console.log("panstart");

    //   // console.log(
    //   //   ev.isFinal,
    //   //   ev.eventType,
    //   //   ev.angle,
    //   //   Math.abs(ev.velocityX / ev.velocity),
    //   //   ev
    //   // );
    //   if (this.isScrolling || this.state.swipeDisabled || ev.isFinal) {
    //     return;
    //   }
    //   this.slideGo = true;
    //   this.setState({ animate: false, swipingActive: true });

    //   this.slideLocation = this.getCenterPosition();
    //   if (ev.direction === 2 || ev.direction === 4) {
    //     this.slideGo = true;
    //   } else {
    //     this.slideGo = false;
    //   }
    //   this.offSetDistance = ev.deltaX;
    //   // 			this.offSetDistance = 0;
    //   // });
    // });
    this.Pager.on("panend", (ev) => {
      //   console.log("panend");

      if (this.isScrolling || this.state.swipeDisabled) {
        this.restoreToMenuState();
        return;
      }
      // window.requestAnimationFrame(() => {
      this.setState({ animate: true, swipingActive: false });
      var location = 0;
      setTimeout(() => {
        if (this.center.current.style.transform) {
          location = parseInt(
            this.center.current.style.transform.split("(")[1].split("px")[0],
            10
          );
        }
        if (ev.maxPointers === 2 && ev.direction === 8) {
          this.hideMenu();
          // this.showBottomMenu();
        } else if (ev.velocityX > 0.6) {
          if (location > 0) {
            this.showLeftMenu();
          } else {
            this.hideMenu();
          }
        } else if (ev.velocityX < -0.6) {
          if (location < 0) {
            this.showRightMenu();
          } else {
            this.hideMenu();
          }
        } else if (
          location < this.state.leftWidth / 2 &&
          location > 0 - this.state.rightWidth / 2
        ) {
          this.hideMenu();
        } else if (location > 0) {
          this.showLeftMenu();
        } else {
          this.showRightMenu();
        }
      }, 10);
      this.slideGo = false;
      // });
    });
  }
  times = [];
  refreshLoop() {
    const now = performance.now();
    while (this.times.length > 0 && this.times[0] <= now - 1000) {
      this.times.shift();
    }
    this.times.push(now);
    let fps = this.times.length;
    console.log("FPS", fps);
  }
  getCenterPosition() {
    var transform = this.center.current.style.transform;
    if (!transform || transform === "") {
      return 0;
    } else {
      return parseInt(transform.split("(")[1].split("px")[0], 10);
    }
  }
  setTranslation(translation = 0) {
    window.requestAnimationFrame(() => {
      // this.center.current.style.zIndex = 10;
      if (translation > 0) {
        this.right.current.style.zIndex = 5;
        this.left.current.style.zIndex = 6;
      } else if (translation < 0) {
        this.right.current.style.zIndex = 6;
        this.left.current.style.zIndex = 5;
      }
      this.center.current.style.transform = "translate(" + translation + "px)";
    });
  }
  showLeftMenu = () => {
    // this.hideMenu();
    this.setTranslation(this.state.leftWidth);
    setTimeout(
      () => this.setState({ menuState: -1 }),
      animationDuration * 1000
    );
  };
  showRightMenu = () => {
    // this.hideMenu();
    this.setTranslation("-" + this.state.rightWidth);
    setTimeout(() => this.setState({ menuState: 1 }), 20);
  };
  restoreToMenuState = () => {
    if (this.state.menuState === -1) {
      this.showLeftMenu();
    }

    if (this.state.menuState === 0) {
      this.hideMenu();
    }

    if (this.state.menuState === 1) {
      this.showRightMenu();
    }
  };
  hideMenu = () => {
    this.setTranslation();
    this.center.current.focus();
    setTimeout(() => this.setState({ menuState: 0 }), 20);
  };
  setDisabled = (disabled) => {
    this.swipeDisabled = disabled;
  };
  render() {
    // console.log("render");
    let style = {
      container: {
        position: "absolute",
        backgroundColor: "inherit",
        width: "100%",
        height: "100%",
        ...(this.props.containerStyle || {}),
        overflowX: "hidden",
      },
      leftView: {
        position: "absolute",
        height: "inherit",
        backgroundColor: "inherit",
        width: this.state.leftWidth + "px",
        top: 0,
        bottom: 0,
        left: 0,
        WebkitOverflowScrolling: "touch",
      },
      rightView: {
        position: "absolute",
        height: "inherit",
        backgroundColor: "inherit",
        width: this.state.rightWidth + "px",
        top: 0,
        bottom: 0,
        right: 0,
        WebkitOverflowScrolling: "touch",
      },
      centerView: {
        position: "absolute",
        height: "inherit",
        backgroundColor: "inherit",
        overflowX: "hidden",
        WebkitOverflowScrolling: "touch",
        zIndex: 1000,
        // overflowY: this.state.swipingActive ? "hidden" : "scroll",
        overflowY: "scroll",
        left: this.state.leftSticky ? this.state.leftWidth : 0,
        right: this.state.rightSticky ? this.state.rightWidth : 0,
        transition: this.state.animate
          ? "transform " + animationDuration + "s cubic-bezier(0.19,1, 0.22, 1)"
          : "none",
      },
    };
    return (
      <div style={style.container} ref={this.container}>
        <div style={style.leftView} ref={this.left}>
          {this.props.leftView || null}
        </div>
        <div style={style.rightView} ref={this.right}>
          {this.props.rightView || null}
        </div>
        <div style={style.centerView} ref={this.center}>
          {this.props.children({
            showLeftMenu: this.showLeftMenu,
            showRightMenu: this.showRightMenu,
            hideMenu: this.hideMenu,
            leftSticky: this.state.leftSticky,
            rightSticky: this.state.rightSticky,
            menuState: this.state.menuState,
            setDisabled: this.setDisabled,
          })}
        </div>
      </div>
    );
  }
}
export default withResizeDetector(WebSwipe);
