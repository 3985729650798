import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import "./components/mainComponents/App.css";
import feathers from "@feathersjs/client";
import io from "socket.io-client";
import FeathersContext from "./feathersContext";
import Auth from "./components/Auth";
import AdminDashboard from "./pages/admin/AdminDashboard";
import ExamDashboard from "./pages/exam/ExamDashboard";
import LanguageSwitcher from "./languageSwitcher";
// Socket.io is exposed as the `io` global.
let socket = null;
if (process.env.REACT_APP_ENV === "DEV") {
  console.log("Dev");
  socket = io("http://192.168.10.10:3030");
} else {
  console.log("Production");
  socket = io("https://cloud.susdeacons.com");
}
// @feathersjs/client is exposed as the `feathers` global.
const app = feathers();

app.configure(feathers.socketio(socket));
app.configure(feathers.authentication());

class App extends React.Component {
  state = { networkConnected: socket.connected };
  componentDidMount() {
    socket.on("connect", () => {
      this.setState({ networkConnected: true }); // true
    });
    socket.on("disconnect", () => {
      this.setState({ networkConnected: false }); // true
    });
  }
  render() {
    return (
      <FeathersContext.Provider
        value={{
          app,
          feathers,
          LC: new LanguageSwitcher(window),
          networkConnected: this.state.networkConnected,
        }}
      >
        <Router>
          <Switch>
            <Route
              path="/exam/:page?/:examCode?"
              render={({ match, history }) => {
                return <ExamDashboard match={match} history={history} />;
              }}
            ></Route>
            <Route
              path="/contactus"
              render={({ match, history }) => {
                return <ExamDashboard match={match} history={history} />;
              }}
            ></Route>
            <Route
              path="/admin"
              render={({ match }) => {
                return (
                  <Auth>
                    <AdminDashboard match={match} />
                  </Auth>
                );
              }}
            ></Route>
            <Route path="/">
              <Redirect to="/exam" />
            </Route>
          </Switch>
        </Router>
      </FeathersContext.Provider>
    );
  }
}

export default App;
