export default class LanguageSwitcher {
  constructor(window) {
    this.window = window;
    this.language = "";
    this.setLanguage("En");
  }
  toLangDigits(str) {
    var id = ["۰", "۱", "۲", "۳", "٤", "٥", "٦", "۷", "۸", "۹"];
    return str.replace(/[0-9]/g, function (w) {
      return id[+w];
    });
  }
  setLanguage(lang) {
    if (lang === this.language) return;
    if (lang === "Ar") {
      // this.window.document
      //     .getElementsByTagName("BODY")
      //     .setAttribute("dir", "rtl");
    } else if (lang === "En") {
      // this.window.document
      //     .getElementsByTagName("BODY")
      //     .setAttribute("dir", "ltr");
    }
    this.language = lang;
  }
  val(english, lang = this.language) {
    let els = this.strings.filter((v) => v.En === english);
    if (!Array.isArray(els) || els.length === 0)
      els = [{ En: english, Ar: this.toLangDigits(english) }];
    let el = els[0];
    return el[lang];
  }
  strings = [
    {
      En: "Start Exam Now",
      Ar: "ابدأ الامتحان الان",
    },
    {
      En: "Enter your exam code below.",
      Ar: "ادخل كود الامتحان اسفل",
    },
    {
      En: "This is the code given to you by the priest who nominated you. If you do not have a code or lost it, please contact your priest.",
      Ar: "الكود ممنوح لك من الكاهن الذي رشحك. برجاء التواصل مع الكاهن اذا فقدت او لم تحصل علي هذا الكود",
    },
    {
      En: "Exam Code",
      Ar: "كود الامتحان",
    },
    {
      En: "Language Chooser",
      Ar: "اختيار اللغه",
    },
    {
      En: "Candidate Information",
      Ar: "بيانات المُرشح",
    },
    {
      En: "Exam Instructions",
      Ar: "تعليمات الامتحان",
    },
    {
      En: "Choose Your Exam Language",
      Ar: "اختار لغه الامتحان",
    },
    {
      En: "* are required fields",
      Ar: "البيانات الاجباريه *",
    },
    {
      En: "It is preferred to fill out the information in english letters",
      Ar: "من المُفضل مليء البيانات باللغه الانجليزيه",
    },
    {
      En: "First Name",
      Ar: "الاسم الاول",
    },
    {
      En: "Middle Name",
      Ar: "الاسم الثاني",
    },
    {
      En: "Last Name",
      Ar: "الاسم العائلي",
    },
    {
      En: "Email Address",
      Ar: "البريد الالكتروني",
    },
    {
      En: "Phone Number",
      Ar: "رقم الهاتف",
    },
    {
      En: "Date of Birth (MM/DD/YYYY)",
      Ar: "تاريخ الميلاد",
    },
    {
      En: "Nominating Priest",
      Ar: "الكاهن المُرشح",
    },
    {
      En: "Other Nominating Priest",
      Ar: "كاهن مرشح اخر",
    },
    {
      En: "Other Nominating Priest (Please Specify)",
      Ar: "كاهن مرشح احر (برجاء ذكره)",
    },
    {
      En: "Please answer the following certification:",
      Ar: ":برجاء الاجابة علي التعهد الاتي",
    },
    {
      En: "Exam Complete",
      Ar: "نهايه الامتحان",
    },
    {
      En: "You have successfully completed the Subdeacon Candidacy exam. The results will be analyzed and sent to the priest of your church very soon. You may close this page.",
      Ar: "اقد قمت بإتمام امتحان مُرشحين الإبذياكونية. سوف يتم حساب النتيجة و إرسالها لكاهن كنيستك قريباً. يمكنك غلق هذه الصفحة",
    },
    {
      En: "This exam is closed book. You cannot use any materials to aid you during the exam.",
      Ar: "لا يمكن استخدام أي كتب أو مذكرات للمساعدة وقت الإمتحان.",
    },
    {
      En: "Number of questions:",
      Ar: "عدد الأسئلة:",
    },
    {
      En: "Has a time limit of:",
      Ar: "لديه مهلة لمدة:",
    },
    {
      En: "Must be finished in one sitting. You cannot save and finish later.",
      Ar: "يجب أن يتم الإنتهاء في جلسة واحدة. لا يمكنك الحفظ والإنتهاء في وقت لاحق.",
    },
    {
      En: "Questions displayed per page:",
      Ar: "الأسئلة المعروضة في كل صفحة:",
    },
    {
      En: "Will allow you to go back and change your answers.",
      Ar: "يسمح لك بأن تعود إلى الوراء وتغير إجاباتك.",
    },
    {
      En: "Will not let you finish with any questions unattempted.",
      Ar: "لن نسمح لك بالإنهاء بوجود أسئلة غير مجاب عليها.",
    },
    {
      En: "Has a pass mark of:",
      Ar: "نسبة النجاح هي:",
    },
    {
      En: "I certify that I took this exam without any assistance from materials (books, study guides, apps, websites, etc.) and without participation, advice, or assistance from others.",
      Ar: "أقر بأنني أجريت هذا الاختبار دون أي مساعدة (الكتب ، أو مذكرات ، أو التطبيقات ، أو المواقع الإلكترونية ، وما إلى ذلك) ودون مشاركة أو مشورة أو مساعدة من الآخرين.",
    },
    {
      En: "I certify",
      Ar: "صواب",
    },
    {
      En: "I do not certify",
      Ar: "خطأ",
    },
    {
      En: "Agree and Start Exam",
      Ar: "موافقه و بدء الامتحان",
    },
    {
      En: "Continue",
      Ar: "متابعة",
    },
    {
      En: "Submit",
      Ar: "اتمام",
    },
    {
      En: 'Click "Continue" below to submit your answers.',
      Ar: 'اضغط "متابعة" لإتمام و ارسال الاجابات',
    },
    {
      En: "Question",
      Ar: "سؤال",
    },
    {
      En: "Previous Question",
      Ar: "السؤال السابق",
    },
    {
      En: "Next Question",
      Ar: "السؤال القادم",
    },
    {
      En: "Complete Exam",
      Ar: "إنهاء الامتحان",
    },
    {
      En: "End",
      Ar: "النهاية",
    },
    {
      En: "Start",
      Ar: "البداية",
    },
    {
      En: "Are you sure?",
      Ar: "هل أنت واثق؟",
    },
    {
      En: "Are you sure you want to complete this exam?",
      Ar: "هل أنت متأكد أنك تريد إكمال هذا الامتحان؟",
    },
    {
      En: "Yes",
      Ar: "نعم",
    },
    {
      En: "No",
      Ar: "لا",
    },
    {
      En: "Saving exam questions for yourself or others by writing them, taking pictures, or any other means is strictly forbidden for both those who do so, those who distribute them, and those who receive such materials. This is cheating and not befitting of a Christian, let alone a servant or rank of the diaconate. We commend all those who have honestly and faithfully taken this exam and we warn all those that have deceitfully taken this exam that the Lord sees and knows what no one else does.",
      Ar: "حفظ اسئلة الامتحان لنفسك او للأخرين سواء بالكتابة او اخذ صور او بأي طريقة هو ممنوع منعاً تاماً سواء لفاعل ذلك او الموزع او المُستقبل. مثل هذا السلوك هو غش و لا يليق بالمسيحي، بالأخص الخادم او رتبة الإبذياكونية. نثني علي كل من أخذ الامتحان بأمانة وصدق، و نُحذر كل من أخذ الامتحان بالغش ان الرب يري ويعلم ما لا يعرفه انسان.",
    },
    {
      En: "If you encounter any technical issues during this exam, you are required to reach out to us immediately at admin@susdeacons.com.",
      Ar: "إذا واجهت أي مشاكل فنية أثناء هذا الاختبار ، فأنت مطالب بالتواصل معنا على الفور على admin@susdeacons.com.",
    },
  ];
}
