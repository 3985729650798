import React from "react";
import Menu from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
// import Sidebar from "react-sidebar";
import WebSwipe from "./WebSwipe";

const sidebarWidth = 320;
const mql = window.matchMedia(`(min-width: 800px)`);

class LeftMenuLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mql: mql,
      mainStyle: {},
      sidebarDocked: mql.matches,
      sidebarOpen: false,
    };
    this.styles = {
      root: {
        width: "100%",
        height: "100%",
        marginTop: 0,
        zIndex: 1000,
        flexDirection: "row",
        display: "flex",
        overflow: "hidden",
      },
      sidebar: {
        width: sidebarWidth,
      },
      overlay: {
        zIndex: 1,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        visibility: "hidden",
        transition: "opacity .3s ease-out, visibility .3s ease-out",
        backgroundColor: "rgba(0,0,0,.3)",
      },
    };
    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }
  componentDidMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  handleDrawerToggle() {
    this.state.sidebarOpen
      ? this.onSetSidebarOpen(false)
      : this.onSetSidebarOpen(true);
  }
  mediaQueryChanged() {
    // if (this.state.sidebarDocked !== mql.matches || this.state.sidebarOpen !== false) {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
    // }
  }
  showing() {
    if (this.state.sidebarOpen || this.state.sidebarDocked) {
      return true;
    }
    return false;
  }
  render() {
    let anchor = (isShowing, showLeft, hideLeft) => (
      <IconButton
        style={{ color: "#fff" }}
        onClick={isShowing ? hideLeft : showLeft}
      >
        {<Menu />}
      </IconButton>
    );
    // console.log(anchor);
    // let header = this.props.header || (() => <div>header</div>);

    return (
      <>
        {/* {header(anchor)} */}
        <WebSwipe
          allowSwipe={false}
          leftWidth={sidebarWidth}
          leftView={this.props.leftDrawer}
          leftStickyMinWidth={800}
        >
          {({ leftSticky, menuState, showLeftMenu, hideMenu }) =>
            this.props.mainContent(
              leftSticky ? (
                <div />
              ) : (
                anchor(menuState < 0, showLeftMenu, hideMenu)
              )
            )
          }
        </WebSwipe>
      </>
    );
  }
}

export default LeftMenuLayout;
