import React from "react";
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "url-search-params-polyfill";

import { AppBar, Toolbar, Typography } from "@material-ui/core";
import ExamPublic from "./ExamPublic";
import Contact from "./Contact";
import ExamLogin from "./ExamLogin";
import LanguageChooser from "./LanguageChooser";
import CandidateInformation from "./CandidateInformation";
import ExamInstructions from "./ExamInstructions";
import ExamProper from "./exam/ExamProper";

import Certification from "./Certification";
import Complete from "./Complete";
import FeathersContext from "../../feathersContext";
import statuses from "./statuses.json";
export default class ExamDashboard extends React.Component {
  static contextType = FeathersContext;

  state = {
    administrations: [],
    churches: {},
    activeAdministration: null,
  };
  async componentDidMount() {
    this.setState({
      activeAdministration: this.props.match.url.split("/")[3] || null,
    });
    await this.getAdministrations();
    this.context.app
      .service("administrations")
      .on("patched", this.updateOnPatched);
    this.context.app
      .service("administrations")
      .on("removed", this.updateOnRemoved);
  }

  // updateOnPatched= message =>
  //   this.setState(state=>{
  //       state.administrations[this.getAdministrationIndex(message._id)] = message;
  //       return state
  //   })

  //   updateOnRemoved= message =>
  //       this.setState(state=>{
  //           let index = this.getAdministrationIndex(message._id);
  //           state.administrations.splice(index,1);
  //       let id = null;
  //       if (state.administrations[index])
  //           id = state.administrations[index]._id;
  //       else if (state.administrations[index - 1])
  //           id = state.administrations[index - 1]._id;
  //       else if (state.administrations[index + 1])
  //           id = state.administrations[index + 1]._id;
  //           this.setActiveAdministration(id);
  //           return state
  //       })

  async componentWillUnmount() {
    this.context.app
      .service("administrations")
      .off("patched", this.updateOnPatched);
    this.context.app
      .service("administrations")
      .off("removed", this.updateOnRemoved);
  }
  async getAdministrations() {
    try {
      let list = await this.context.app.service("administrations").find();
      list.data.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });

      this.setState({ administrations: list.data });
      console.log(list);
      if (
        this.state.administrations.length &&
        this.state.activeAdministration === null
      )
        this.setActiveAdministration(this.state.administrations[0]._id);
    } catch (e) {}
  }
  async addAdministration() {
    try {
      let newAdministration = await this.context.app
        .service("administrations")
        .create({});
      console.log("Add", newAdministration);

      await this.getAdministrations();
      this.setActiveAdministration(newAdministration._id);
    } catch (e) {}
  }
  setActiveAdministration(id) {
    this.setState({ activeAdministration: id });
    let url = this.props.match.url.split("/");
    url[3] = id;
    this.props.history.replace(url.join("/"));
  }

  render() {
    // let match = this.props.match;
    return (
      <div
        style={{
          height: "inherit",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AppBar position="static" style={{ flexGrow: 0 }}>
          <Toolbar>
            <Typography
              style={{ fontWeight: "800", flexGrow: 1 }}
              variant="h6"
              noWrap
            >
              Coptic Orthodox Diocese of the Southern United States - Subdeacon
              Candidacy Exam
            </Typography>
          </Toolbar>
        </AppBar>
        <ExamPagesSwitch parent={this} />
      </div>
    );
  }
}

class ExamPagesSwitch extends React.Component {
  static contextType = FeathersContext;
  state = {
    examCode: this.props.parent.props.match.params.examCode || null,
    examStatus: this.props.parent.props.match.params.page,
    language: "",
    setStatus: this.setStatus.bind(this),
    goToNextPage: this.goToNextPage.bind(this),
    goToPreviousPage: this.goToPreviousPage.bind(this),
    goToCurrentPage: this.goToCurrentPage.bind(this),
  };
  async setStatus(status, examCode = this.state.examCode) {
    console.log(status);

    await this.context.app.service("publicexam").create({
      func: "setStatus",
      params: { examCode, status },
    });
    this.setState({ examStatus: status });

    console.log(status);
  }
  async goToNextPage() {
    let currentStatusIndex = statuses.indexOf(this.state.examStatus);
    if (currentStatusIndex < statuses.length - 1) {
      console.log(statuses[currentStatusIndex + 1]);
      await this.setStatus(statuses[currentStatusIndex + 1]);
    } else return null;
  }
  async goToPreviousPage() {
    let currentStatusIndex = statuses.indexOf(this.state.examStatus);
    if (currentStatusIndex > 0) {
      await this.setStatus(statuses[currentStatusIndex - 1]);
    } else return null;
  }
  async goToCurrentPage(examCode) {
    this.setState({ examCode });
    let status = await this.getCurrentStatus();
    this.props.parent.props.history.push(
      "/exam/" + status + "/" + (examCode || "")
    );
  }
  componentDidMount = this.componentDidUpdate;
  async componentDidUpdate(prevProps, prevState) {
    await this.getCurrentStatus();
    if (
      this.state.examStatus &&
      this.props.parent.props.match.params.page !== this.state.examStatus
    ) {
      this.props.parent.props.history.replace(
        "/exam/" + this.state.examStatus + "/" + (this.state.examCode || "")
      );
    }
    if (this.state.examStatus === "exam" && !this.state.language) {
      let lang = await this.context.app.service("publicexam").create({
        func: "getLanguage",
        params: { examCode: this.state.examCode },
      });
      console.log(lang, this.state);
      this.setState({
        language: lang,
      });
      console.log(lang, this.state);
    }
  }
  async getCurrentStatus() {
    let status = "";
    if (this.state.examCode) {
      status = await this.context.app.service("publicexam").create({
        func: "getStatus",
        params: { examCode: this.state.examCode },
      });
    }
    if (status !== this.state.examStatus) this.setState({ examStatus: status });

    return status;
  }
  render() {
    return (
      <Switch>
        <Route
          path="/contactus/"
          render={({ match, history }) => {
            return <Contact {...this.state} match={match} history={history} />;
          }}
        ></Route>
        <Route
          path="/exam/login/"
          render={({ match, history }) => {
            return (
              <ExamLogin {...this.state} match={match} history={history} />
            );
          }}
        ></Route>
        <Route
          path="/exam/languageChooser/:examCode"
          render={({ match, history }) => {
            return (
              <LanguageChooser
                {...this.state}
                match={match}
                history={history}
              />
            );
          }}
        ></Route>
        <Route
          path="/exam/candidateInfo/:examCode"
          render={({ match, history }) => {
            return (
              <CandidateInformation
                {...this.state}
                match={match}
                history={history}
              />
            );
          }}
        ></Route>
        <Route
          path="/exam/instructions/:examCode"
          render={({ match, history }) => {
            return (
              <ExamInstructions
                {...this.state}
                match={match}
                history={history}
              />
            );
          }}
        ></Route>
        <Route
          path="/exam/exam/:examCode"
          render={({ match, history }) => {
            return (
              <ExamProper {...this.state} match={match} history={history} />
            );
          }}
        ></Route>
        {/* <Route
                    path="/exam/exam/:examCode"
                    render={({ match, history }) => {
                        if (!this.state.language.length)
                            return <div>Please Wait</div>;
                        else if (this.state.language === "En")
                            window.location.replace(
                                "https://www.classmarker.com/online-test/start/?quiz=ay95ea6727366569&cm_user_id=" +
                                    this.state.examCode
                            );
                        else if (this.state.language === "Ar")
                            window.location.replace(
                                "https://www.classmarker.com/online-test/start/?quiz=r395ea9cfe0e7cbf&cm_user_id=" +
                                    this.state.examCode
                            );
                    }}
                ></Route> */}
        <Route
          path="/exam/post"
          render={({ match, history }) => {
            let examCode = new URLSearchParams(window.location.search).get(
              "cm_user_id"
            );
            if (this.state.examCode !== examCode)
              this.setState({
                examCode,
              });
            this.setStatus("certification", examCode);
            return <div>Please Wait</div>;
          }}
        ></Route>
        <Route
          path="/exam/certification/:examCode"
          render={({ match, history }) => {
            return (
              <Certification {...this.state} match={match} history={history} />
            );
          }}
        ></Route>
        <Route
          path="/exam/complete/:examCode"
          render={({ match, history }) => {
            return <Complete {...this.state} match={match} history={history} />;
          }}
        ></Route>
        <Route path="/exam/current/:examCode">
          <Redirect
            to={"/google/" + this.state.examStatus + "/" + this.state.examCode}
          />
        </Route>
        <Route
          path="/exam/"
          render={({ match, history }) => {
            return (
              <ExamPublic {...this.state} match={match} history={history} />
            );
          }}
        ></Route>
      </Switch>
      // <div
      //     style={{
      //         padding: "0px",
      //         paddingTop: "0",
      //         flexGrow: 1,
      //         overflow: "hidden",
      //     }}
      // >
      //     {examPage}
      // </div>
    );
  }
}
