import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import { Container, Card, Typography } from "@material-ui/core";
export default class extends Component {
  static contextType = FeathersContext;
  state = {};
  async componentDidMount() {
    let language = await this.context.app.service("publicexam").create({
      func: "getLanguage",
      params: { examCode: this.props.examCode },
    });
    this.context.LC.setLanguage(language);
    this.setState({ language });
  }

  async handleCertifyClick() {
    try {
      await this.context.app.service("publicexam").create({
        func: "setCertification",
        params: { id: this.props.examCode, certification: true },
      });
      await this.props.goToNextPage();
    } catch (e) {
      console.log(e);
    }
  }
  async handleNotCertifyClick() {
    try {
      await this.context.app.service("publicexam").create({
        func: "setCertification",
        params: { id: this.props.examCode, certification: false },
      });
      await this.props.goToNextPage();
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Container maxWidth={"sm"} style={{ marginTop: 150 }}>
          <Card raised style={{ padding: 20 }}>
            <div dir={this.state.language === "Ar" ? "rtl" : "ltr"}>
              <Typography variant="h5" gutterBottom>
                {this.context.LC.val("Exam Complete")}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                align={this.state.language === "Ar" ? "right" : "left"}
              >
                {this.context.LC.val(
                  "You have successfully completed the Subdeacon Candidacy exam. The results will be analyzed and sent to the priest of your church very soon. You may close this page."
                )}
              </Typography>
            </div>
          </Card>
        </Container>
      </div>
    );
  }
}
