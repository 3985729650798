import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  List,
  ListItem,
  Box,
  ButtonGroup,
  Chip,
  Divider,
} from "@material-ui/core";
import FeathersContext from "../../feathersContext";
import LeftMenuLayout from "../../components/left-menu-detail";
import CaserCard from "../../components/mainComponents/card";

function RTL(props) {
  return <div dir="rtl">{props.children}</div>;
}

let Letters = "ABCDEFGHIJKL";

export default class Questions extends React.Component {
  static contextType = FeathersContext;
  state = {
    questions: [],
    activeQuestion: null,
    category: "all",
  };
  async componentDidMount() {
    this.setState({
      activeQuestion: this.props.match.url.split("/")[3] || null,
    });
    await this.getQuestions();
    this.context.app.service("questions").on("patched", this.updateOnPatched);
    this.context.app.service("questions").on("removed", this.updateOnRemoved);
  }

  updateOnPatched = (message) =>
    this.setState((state) => {
      state.questions[this.getQuestionIndex(message._id)] = message;
      return state;
    });

  updateOnRemoved = (message) =>
    this.setState((state) => {
      let index = this.getQuestionIndex(message._id);
      state.questions.splice(index, 1);
      let id = null;
      if (state.questions[index]) id = state.questions[index]._id;
      else if (state.questions[index - 1]) id = state.questions[index - 1]._id;
      else if (state.questions[index + 1]) id = state.questions[index + 1]._id;
      this.setActiveQuestion(id);
      return state;
    });

  async componentWillUnmount() {
    this.context.app.service("questions").off("patched", this.updateOnPatched);
    this.context.app.service("questions").off("removed", this.updateOnRemoved);
  }
  async getQuestions() {
    try {
      let list = await this.context.app
        .service("questions")
        .find({ query: { $limit: 1200 } });
      this.setState({ questions: list.data });
      console.log(list);
      if (this.state.questions.length && this.state.activeQuestion === null)
        this.setActiveQuestion(this.state.questions[0]._id);
    } catch (e) {}
  }
  async addQuestion() {
    try {
      let newQuestion = await this.context.app.service("questions").create({
        Type: "multiplechoice",
        Question: { En: "", Ar: "" },
        Choices: {
          En: { A: "", B: "", C: "", D: "" },
          Ar: { A: "", B: "", C: "", D: "" },
        },
        Status: "Review",
        RandomizeAnswers: true,
        Difficulty: "Easy",
      });
      console.log("Add", newQuestion);

      await this.getQuestions();
      this.setActiveQuestion(newQuestion._id);
    } catch (e) {}
  }
  setActiveQuestion(id) {
    this.setState({ activeQuestion: id });
    let url = this.props.match.url.split("/");
    url[3] = id;
    this.props.history.replace(url.join("/"));
  }
  getQuestionIndex(id = this.state.activeQuestion) {
    for (let [index, question] of this.state.questions.entries()) {
      if (question._id === id) {
        return index;
      }
    }
    return -1;
  }
  render() {
    return (
      <LeftMenuLayout
        leftDrawer={
          <div
            style={{
              height: "inherit",
              background: "#ECEFF1",
              overflowY: "scroll",
            }}
          >
            <List>
              {this.state.questions
                .filter((v) => {
                  if (this.state.category === "all") return true;
                  if (this.state.category === "mc")
                    return v.Type === "multiplechoice";
                  if (this.state.category === "tf")
                    return v.Type === "truefalse";
                  if (this.state.category === "included")
                    return v.Status === "Included";
                  if (this.state.category === "review")
                    return v.Status === "Review";
                  if (this.state.category === "removed")
                    return v.Status === "Removed";
                  if (this.state.category === "easy")
                    return v.Difficulty === "Easy";
                  if (this.state.category === "hard")
                    return v.Difficulty !== "Easy";
                  if (this.state.category === "randomized")
                    return v.RandomizeAnswers === true;
                  if (this.state.category === "ordered")
                    return v.RandomizeAnswers !== true;
                  return false;
                })
                .map((v, i) => (
                  <ListItem
                    button
                    style={{
                      width: "inherit",
                      fontWeight: 600,
                      padding: "15px 10px",
                      background:
                        this.state.activeQuestion === v._id ? "#B0BEC5" : "",
                    }}
                    onClick={() => this.setActiveQuestion(v._id)}
                    key={v._id}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "block",
                      }}
                    >
                      <Typography noWrap variant={"body2"}>
                        <b>
                          {i + 1}: {v.Question.En}
                        </b>
                      </Typography>
                      <br />
                      <Box display={"flex"} flexDirection={"row"}>
                        <Chip
                          color="primary"
                          label={
                            (v.Difficulty === "Hard" ? "H" : "E") +
                            " - " +
                            (v.Type === "multiplechoice" ? "MC" : "TF")
                          }
                          size={"small"}
                        />

                        <Box flexGrow={1} />
                        <Chip
                          color="default"
                          label={
                            v.RandomizeAnswers === true
                              ? "Randomized"
                              : "Ordered"
                          }
                          size={"small"}
                        />
                        <Chip
                          color="secondary"
                          label={v.Status}
                          size={"small"}
                        />
                      </Box>
                    </div>
                    <Divider />
                  </ListItem>
                ))}
            </List>
          </div>
        }
        mainContent={(anchor) => (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 0,
                background: "#546E7A",
                color: "#fff",
                padding: 10,
              }}
            >
              <Typography variant="h6" style={{ paddingRight: "10px" }}>
                {anchor} Questions
              </Typography>
              <Select
                color="primary"
                size="small"
                margin={"dense"}
                variant={"outlined"}
                value={this.state.category}
                onChange={(e) => {
                  this.setState({
                    category: e.target.value,
                  });
                }}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"mc"}>Multiple Choice</MenuItem>
                <MenuItem value={"tf"}>True/False</MenuItem>
                <MenuItem value={"included"}>Included</MenuItem>
                <MenuItem value={"review"}>Under Review</MenuItem>
                <MenuItem value={"removed"}>Removed</MenuItem>
                <MenuItem value={"easy"}>Easy</MenuItem>
                <MenuItem value={"hard"}>Hard</MenuItem>
                <MenuItem value={"randomized"}>Randomized Choices</MenuItem>
                <MenuItem value={"ordered"}>Ordered Choices</MenuItem>
              </Select>
              <Box flexGrow={1} />
              <Button
                color="inherit"
                onClick={this.addQuestion.bind(this)}
                style={{ flexGrow: 0, fontWeight: "bold" }}
              >
                New Question
              </Button>
            </div>
            <div
              style={{
                flexGrow: 3,
                background: "#CFD8DC",
                overflowY: "scroll",
                paddingBottom: 30,
              }}
            >
              <QuestionDetails parent={this} id={this.state.activeQuestion} />
            </div>
          </>
        )}
      />
    );
  }
}

class QuestionDetails extends React.Component {
  static contextType = FeathersContext;
  state = { data: null, deleteDialogOpen: false };
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getQuestion();
    }
  }
  async getQuestion() {
    if (this.props.id === null) {
      return;
    }
    this.setState({ data: null });
    try {
      let data = await this.context.app.service("questions").get(this.props.id);
      this.setState({ data });
    } catch (e) {
      console.log(e);
    }
  }
  updateQuestion(language, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      let data = {};
      this.setState((state) => {
        state.data["Question"][language] = value;
        data.Question = state.data["Question"];
        return state;
      });
      if (data.Question[language].trim)
        data.Question[language] = data.Question[language].trim();
      this.updateFieldServer(this.state.data._id, data);
    } catch (e) {
      console.log(e);
    }
  }
  updateChoices(language, name, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      let data = {};
      this.setState((state) => {
        state.data.Choices[language][name] = value;
        data.Choices = state.data.Choices;
        if (data.Choices[language][name].trim)
          data.Choices[language][name] = data.Choices[language][name].trim();

        this.updateFieldServer(this.state.data._id, data);
        return state;
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateField(fieldName, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      this.setState((state) => {
        state.data[fieldName] = value;
        return state;
      });
      let data = {
        [fieldName]: value,
      };
      if (data[fieldName].trim) data[fieldName] = data[fieldName].trim();
      this.updateFieldServer(this.state.data._id, data);
    } catch (e) {
      console.log(e);
    }
  }
  async updateFieldServer(id, data) {
    await this.context.app.service("questions").patch(id, data);
  }
  async removeQuestion() {
    if (this.props.id === null) {
      return;
    }
    try {
      await this.context.app.service("questions").remove(this.props.id);

      this.setState({ deleteDialogOpen: false });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    if (this.props.id === null)
      return <div>Select a Question to edit the details.</div>;
    else if (this.state.data === null) {
      return null;
    } else {
      return (
        <div>
          <Container maxWidth="md">
            <br />
            <br />
            <CaserCard
              style={{ padding: "20px", textAlign: "center" }}
              header={"Question Options"}
              footer={
                <Box display="flex" style={{ alignItems: "center" }}>
                  <Typography variant={"caption"}>
                    Question ID: {this.state.data._id}
                  </Typography>
                  <Box flexGrow={1} />{" "}
                  <Button
                    color="secondary"
                    onClick={() =>
                      this.setState({
                        deleteDialogOpen: true,
                      })
                    }
                  >
                    Delete
                  </Button>
                </Box>
              }
            >
              <Box
                textAlign={"left"}
                display="flex"
                flexDirection="column"
                style={{ textAlign: "start" }}
              >
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color={
                      this.state.data.Status === "Included"
                        ? "primary"
                        : "default"
                    }
                    onClick={() => this.updateField("Status", "Included")}
                  >
                    Included
                  </Button>
                  <Button
                    variant="contained"
                    color={
                      this.state.data.Status === "Review"
                        ? "primary"
                        : "default"
                    }
                    onClick={() =>
                      this.updateField(
                        "Status",

                        "Review"
                      )
                    }
                  >
                    In Review
                  </Button>
                  <Button
                    variant="contained"
                    color={
                      this.state.data.Status === "Removed"
                        ? "primary"
                        : "default"
                    }
                    onClick={() =>
                      this.updateField(
                        "Status",

                        "Removed"
                      )
                    }
                  >
                    Removed
                  </Button>
                </ButtonGroup>
                <br />
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color={
                      this.state.data.Difficulty === "Easy"
                        ? "primary"
                        : "default"
                    }
                    onClick={() => this.updateField("Difficulty", "Easy")}
                  >
                    Easy
                  </Button>

                  <Button
                    variant="contained"
                    color={
                      this.state.data.Difficulty === "Hard"
                        ? "primary"
                        : "default"
                    }
                    onClick={() => this.updateField("Difficulty", "Hard")}
                  >
                    Hard
                  </Button>
                </ButtonGroup>
                <br />
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color={
                      this.state.data.RandomizeAnswers ? "primary" : "default"
                    }
                    onClick={() => this.updateField("RandomizeAnswers", true)}
                  >
                    Randomized Choices
                  </Button>
                  <Button
                    variant="contained"
                    color={
                      this.state.data.RandomizeAnswers !== true
                        ? "primary"
                        : "default"
                    }
                    onClick={() => this.updateField("RandomizeAnswers", false)}
                  >
                    Ordered Choices
                  </Button>
                </ButtonGroup>
                <br />
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color={
                      this.state.data.Type === "multiplechoice"
                        ? "primary"
                        : "default"
                    }
                    onClick={() => this.updateField("Type", "multiplechoice")}
                  >
                    Multiple Choice
                  </Button>
                  <Button
                    variant="contained"
                    color={
                      this.state.data.Type === "truefalse"
                        ? "primary"
                        : "default"
                    }
                    onClick={() => this.updateField("Type", "truefalse")}
                  >
                    True or False
                  </Button>
                </ButtonGroup>
              </Box>
            </CaserCard>
            <CaserCard header="Question">
              <Box display="flex" flexDirection={"row"}>
                <Box flexGrow={5}>
                  <TextField
                    fullWidth
                    multiline
                    variant="filled"
                    label="Question"
                    value={this.state.data.Question.En}
                    onChange={(e) => {
                      this.updateQuestion("En", e.target.value);
                    }}
                  />
                </Box>
                <Box flexGrow={5}>
                  <div dir={"rtl"}>
                    <TextField
                      fullWidth
                      multiline
                      variant="filled"
                      value={this.state.data.Question.Ar}
                      onChange={(e) => {
                        this.updateQuestion("Ar", e.target.value);
                      }}
                    />
                  </div>
                </Box>
              </Box>
            </CaserCard>
            <CaserCard
              header="Choices"
              footer={
                <Box display="flex" flexDirection={"row"}>
                  <Button
                    variant="contained"
                    color={"primary"}
                    onClick={() => {
                      this.setState((s) => {
                        let go =
                          Letters[
                            Object.keys(this.state.data.Choices.En).length
                          ];
                        s.data.Choices.En[go] = "";
                        s.data.Choices.Ar[go] = "";
                        return s;
                      });
                      this.updateField("Choices", this.state.data.Choices);
                    }}
                  >
                    Add Choice
                  </Button>
                  <Box flexGrow={1} />

                  <Button
                    variant="contained"
                    color={"secondary"}
                    onClick={() => {
                      this.setState((s) => {
                        let go = Object.keys(this.state.data.Choices.En)
                          .slice(-1)
                          .pop();
                        delete s.data.Choices.En[go];
                        delete s.data.Choices.Ar[go];
                        return s;
                      });
                      this.updateField("Choices", this.state.data.Choices);
                    }}
                  >
                    Remove Choice{" "}
                    {Object.keys(this.state.data.Choices.En).slice(-1).pop()}
                  </Button>
                </Box>
              }
            >
              {Object.keys(this.state.data.Choices.En).map((Name, i) => (
                <Box display="flex" flexDirection={"row"} key={i}>
                  <Box flexGrow={5}>
                    <TextField
                      fullWidth
                      multiline
                      variant="filled"
                      label={"Choice " + Name}
                      value={this.state.data.Choices.En[Name]}
                      onChange={(e) => {
                        this.updateChoices("En", Name, e.target.value);
                      }}
                    />
                  </Box>
                  <Box flexGrow={5}>
                    <RTL>
                      <div dir={"rtl"}>
                        <TextField
                          fullWidth
                          multiline
                          variant="filled"
                          value={this.state.data.Choices.Ar[Name]}
                          onChange={(e) => {
                            this.updateChoices("Ar", Name, e.target.value);
                          }}
                        />
                      </div>
                    </RTL>
                  </Box>
                  <Button
                    variant="contained"
                    color={
                      this.state.data.Answer === Name ? "primary" : "default"
                    }
                    onClick={() =>
                      this.updateField(
                        "Answer",

                        Name
                      )
                    }
                  >
                    Correct
                  </Button>
                </Box>
              ))}
              <br />
              <TextField
                fullWidth
                multiline
                variant="filled"
                label="Notes"
                value={this.state.data.notes}
                onChange={(e) => {
                  this.updateField("notes", e.target.value);
                }}
              />
            </CaserCard>
          </Container>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            open={this.state.deleteDialogOpen}
          >
            <DialogTitle id="confirmation-dialog-title">
              Are you sure?
            </DialogTitle>
            <DialogContent>
              Are you sure you want to permenantly delete this question from the
              database?
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => this.setState({ deleteDialogOpen: false })}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={this.removeQuestion.bind(this)} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
}
