import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import { Button, AppBar, Toolbar, Typography } from "@material-ui/core";
import Churches from "./Churches";
import Fathers from "./Fathers";
import Candidates from "./Candidates";
import Administrations from "./Administrations";
import Questions from "./Questions";

export default class AdminDashboard extends React.Component {
  render() {
    let match = this.props.match;
    return (
      <div
        style={{ height: "inherit", display: "flex", flexDirection: "column" }}
      >
        <AppBar position="static" style={{ flexGrow: 0 }}>
          <Toolbar>
            <Typography
              style={{ fontWeight: "800", flexGrow: 1 }}
              variant="h6"
              noWrap
            >
              Dashboard
            </Typography>
            <NavLink to={`${match.url}/churches/`}>
              <Button style={{ fontWeight: "800" }} color="inherit">
                Churches
              </Button>
            </NavLink>
            <NavLink to={`${match.url}/fathers/`}>
              <Button style={{ fontWeight: "800" }} color="inherit">
                Fathers
              </Button>
            </NavLink>
            <NavLink to={`${match.url}/administrations`}>
              <Button style={{ fontWeight: "800" }} color="inherit">
                Exams
              </Button>
            </NavLink>
            {/* <NavLink to={`${match.url}/candidates/`}>
              <Button style={{ fontWeight: "800" }} color="inherit">
                Candidates
              </Button>
            </NavLink> */}
            <NavLink to={`${match.url}/questions/`}>
              <Button style={{ fontWeight: "800" }} color="inherit">
                Questions
              </Button>
            </NavLink>

            <Button color="inherit" onClick={this.props.logoutFunc}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <div
          style={{
            padding: "0px",
            paddingTop: "0",
            flexGrow: 1,
            overflow: "hidden",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            background: "#fff",
          }}
        >
          <Switch>
            <Route
              path={`${match.path}/churches/:churchId?`}
              children={({ location, history, match }) => (
                <Churches match={match} location={location} history={history} />
              )}
            />
            <Route
              path={`${match.path}/fathers/:fatherId?`}
              children={({ location, history, match }) => (
                <Fathers match={match} location={location} history={history} />
              )}
            />
            <Route path={`${match.path}/administrations`}>
              <Route
                path={`${match.path}/administrations/:administrationsId?`}
                children={({ location, history, match }) => (
                  <Administrations
                    match={match}
                    location={location}
                    history={history}
                  />
                )}
              />
            </Route>
            <Route
              path={`${match.path}/candidates/:candidateId?`}
              children={({ location, history, match }) => (
                <Candidates
                  match={match}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path={`${match.path}/questions/:questionId?`}
              children={({ location, history, match }) => (
                <Questions
                  match={match}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route path={match.path}>
              <h3>Please select a topic.</h3>
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}
