import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import { Container, Typography } from "@material-ui/core";
import Card from "./../../components/mainComponents/card";

export default class extends Component {
  static contextType = FeathersContext;

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Container maxWidth={"sm"} style={{ marginTop: 150 }}>
          <Card header={"Contact Us"}>
            <div>
              <Typography variant="h6" gutterBottom align={"left"}>
                If you have any questions or concerns, please contact us at{" "}
                <a href={"mailto:admin@susdeacons.com"}>admin@susdeacons.com</a>
              </Typography>
            </div>
          </Card>
        </Container>
      </div>
    );
  }
}
