import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Button,
  TextField,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Select,
  Box,
} from "@material-ui/core";
import FeathersContext from "../../feathersContext";
import CaserCard from "../../components/mainComponents/card";
import LeftMenuLayout from "../../components/left-menu-detail";

export default class Fathers extends React.Component {
  static contextType = FeathersContext;
  state = {
    fathers: [],
    allChurches: [],
    activeFather: null,
  };
  async componentDidMount() {
    // console.log(this.props.match.url.split("/"));
    // console.log(this.props.history);
    // console.log(this.props.location);
    this.props.match.params.fatherId = "ggg";
    this.setState({
      activeFather: this.props.match.url.split("/")[3] || null,
    });
    await this.getFathers();
    this.context.app.service("fathers").on("patched", this.updateOnPatched);
    this.context.app.service("fathers").on("removed", this.updateOnRemoved);
  }
  updateOnPatched = (message) =>
    this.setState((state) => {
      state.fathers[this.getFatherIndex(message._id)] = message;
      return state;
    });

  updateOnRemoved = (message) =>
    this.setState((state) => {
      let index = this.getFatherIndex(message._id);
      state.fathers.splice(index, 1);
      let id = null;
      if (state.fathers[index]) id = state.fathers[index]._id;
      else if (state.fathers[index - 1]) id = state.fathers[index - 1]._id;
      else if (state.fathers[index + 1]) id = state.fathers[index + 1]._id;
      this.setActiveFather(id);
      return state;
    });

  async componentWillUnmount() {
    this.context.app.service("fathers").off("patched", this.updateOnPatched);
    this.context.app.service("fathers").off("removed", this.updateOnRemoved);
  }
  async getFathers() {
    try {
      let list = await this.context.app.service("fathers").find();
      list.data.sort((a, b) => {
        if (a.firstName + a.lastName < b.firstName + b.lastName) {
          return -1;
        }
        if (a.firstName + a.lastName > b.firstName + b.lastName) {
          return 1;
        }
        return 0;
      });
      this.setState({ fathers: list.data });
      if (this.state.fathers.length && this.state.activeFather === null)
        this.setActiveFather(this.state.fathers[0]._id);
      await this.getChurches();
    } catch (e) {}
  }
  async getChurches() {
    try {
      let list = await this.context.app.service("churches").find();
      list.data.sort((a, b) => {
        if (
          a.churchName + a.churchCity + a.churchState <
          b.churchName + b.churchCity + b.churchState
        ) {
          return -1;
        }
        if (
          a.churchName + a.churchCity + a.churchState >
          b.churchName + b.churchCity + b.churchState
        ) {
          return 1;
        }
        return 0;
      });
      this.setState({ allChurches: list.data });
    } catch (e) {}
  }

  async addFather() {
    try {
      let newFather = await this.context.app.service("fathers").create({});
      await this.getFathers();
      this.setActiveFather(newFather._id);
    } catch (e) {}
  }
  setActiveFather(id) {
    this.setState({ activeFather: id });
    let url = this.props.match.url.split("/");
    url[3] = id;
    this.props.history.replace(url.join("/"));
  }
  getFatherIndex(id = this.state.activeFather) {
    for (let [index, father] of this.state.fathers.entries()) {
      if (father._id === id) {
        return index;
      }
    }
    return -1;
  }

  render() {
    return (
      <LeftMenuLayout
        leftDrawer={
          <div
            style={{
              background: "#fff",
              overflowY: "scroll",
              // paddingBottom: "20px",
              height: "inherit",
            }}
          >
            <List>
              {this.state.fathers.map((v) => (
                <ListItem
                  button
                  style={{
                    fontWeight: 600,
                    padding: "15px 10px",
                    background:
                      this.state.activeFather === v._id ? "#B0BEC5" : "",
                  }}
                  onClick={() => this.setActiveFather(v._id)}
                  key={v._id}
                >
                  Fr. {v.firstName} {v.lastName}
                </ListItem>
              ))}
            </List>{" "}
          </div>
        }
        mainContent={(anchor) => (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 0,
                background: "#546E7A",
                color: "#fff",
                padding: 10,
              }}
            >
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                {anchor} Fathers
              </Typography>
              <Button
                color="inherit"
                onClick={this.addFather.bind(this)}
                style={{ flexGrow: 0, fontWeight: "bold" }}
              >
                New Father
              </Button>
            </div>

            <div
              style={{
                background: "#CFD8DC",
                overflowY: "scroll",
                paddingBottom: 30,
              }}
            >
              <FatherDetails parent={this} id={this.state.activeFather} />
            </div>
          </>
        )}
      />
    );
  }
}

class FatherDetails extends React.Component {
  static contextType = FeathersContext;

  state = {
    data: null,
    churches: [],
    deleteDialogOpen: false,
  };
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getFather();
    }
  }
  async getFather() {
    if (this.props.id === null) {
      return;
    }
    this.setState({ data: null });
    try {
      let data = await this.context.app.service("fathers").get(this.props.id);
      this.setState({ data });
      await this.getFatherChurches();
    } catch (e) {
      console.log(e);
    }
  }
  async removeFather() {
    if (this.props.id === null) {
      return;
    }
    try {
      await this.context.app.service("fathers").remove(this.props.id);
      await this.props.parent.getChurchFathers();
    } catch (e) {
      console.log(e);
    }
  }

  async getFatherChurches() {
    this.setState({ churches: [] });
    if (
      !Array.isArray(this.state.data.churchIds) ||
      !this.state.data.churchIds.length
    ) {
      return;
    }
    try {
      let churches = await this.context.app.service("churches").find({
        query: {
          _id: {
            $in: this.state.data.churchIds,
          },
        },
      });
      this.setState({ churches: churches.data });
    } catch (e) {
      console.log(e);
    }
  }

  updateField(fieldName, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      let data = {
        // _id: this.state.data._id,
        [fieldName]: value,
      };
      this.setState({ data: Object.assign(this.state.data, data) });
      if (typeof data[fieldName] === "string")
        data[fieldName] = data[fieldName].trim();
      this.updateFieldServer(this.state.data._id, data);
    } catch (e) {
      console.log(e);
    }
  }
  async updateFieldServer(id, data) {
    await this.context.app.service("fathers").patch(id, data);
  }

  render() {
    if (this.props.id === null)
      return <div>Select a Father to edit the details.</div>;
    else if (this.state.data === null) {
      return null;
    } else {
      return (
        <>
          <CaserCard
            style={{ padding: "20px", textAlign: "center" }}
            header={
              <Box>
                Fr. {this.state.data.firstName} {this.state.data.lastName}
              </Box>
            }
            footer={
              <Box display="flex" style={{ alignItems: "center" }}>
                <Typography variant="caption">
                  Database ID: {this.state.data._id}
                </Typography>
                <Box flexGrow={1} />
                <Button
                  size="small"
                  color="secondary"
                  onClick={() =>
                    this.setState({
                      deleteDialogOpen: true,
                    })
                  }
                >
                  Delete
                </Button>
              </Box>
            }
          >
            <Typography color="primary" variant="subtitle1">
              Fr. {this.state.data.firstName} {this.state.data.lastName}
            </Typography>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Fr.</InputAdornment>
                ),
              }}
              label="First Name"
              prefix={"Fr."}
              value={this.state.data.firstName}
              onChange={(e) => {
                this.updateField("firstName", e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="Last Name"
              prefix={"Fr."}
              value={this.state.data.lastName}
              onChange={(e) => {
                this.updateField("lastName", e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              value={this.state.data.emailAddress}
              onChange={(e) => {
                this.updateField("emailAddress", e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="CC Email Address"
              type="email"
              value={this.state.data.ccEmailAddress}
              onChange={(e) => {
                this.updateField("ccEmailAddress", e.target.value);
              }}
            />
            <TextField
              fullWidth
              label="Phone Number"
              type="phone"
              value={this.state.data.phoneNumber}
              onChange={(e) => {
                this.updateField("phoneNumber", e.target.value);
              }}
            />
          </CaserCard>

          <CaserCard
            style={{ padding: "20px", textAlign: "center" }}
            header={"Churches"}
            footer={
              <Select
                color="primary"
                variant={"outlined"}
                size="small"
                margin="none"
                multiple
                value={this.state.data.churchIds || []}
                onChange={(e) => {
                  this.updateField("churchIds", e.target.value);
                  this.getFatherChurches();
                }}
                displayEmpty
                renderValue={(v) => {
                  return "Assign Churches";
                }}
              >
                {this.props.parent.state.allChurches.map((v) => (
                  <MenuItem key={v._id} value={v._id}>
                    {v.churchName} {v.churchCity},{v.churchState}
                  </MenuItem>
                ))}
                )
              </Select>
            }
          >
            {this.state.churches.map((v) => (
              <CaserCard
                key={v._id}
                header={
                  <Box display="flex">
                    {v.churchName} {v.churchCity}, {v.churchState}
                    <Box flexGrow={1} />
                    <Button
                      href={"/admin/churches/" + v._id}
                      variant="contained"
                      size="small"
                    >
                      Go
                    </Button>{" "}
                  </Box>
                }
              />
            ))}
          </CaserCard>

          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            open={this.state.deleteDialogOpen}
          >
            <DialogTitle id="confirmation-dialog-title">
              Are you sure?
            </DialogTitle>
            <DialogContent>
              Are you sure you want to permenantly delete this father from the
              database?
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => this.setState({ deleteDialogOpen: false })}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.removeFather();
                  this.setState({ deleteDialogOpen: false });
                }}
                color="primary"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }
}
