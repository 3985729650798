import React from "react";
import TextBar from "./textBar";

export default class Card extends React.Component {
  render() {
    var header;
    if (this.props.header) {
      header = this.props.header;
    } else {
      header = (
        <TextBar
          style={this.props.headerStyle || {}}
          right={
            <div style={{ textAlign: "right", flexGrow: 1 }}>
              {this.props.right || this.props.extra || ""}
            </div>
          }
          left={this.props.left || ""}
        >
          {this.props.title || ""}
        </TextBar>
      );
    }
    var footer = null;
    if (this.props.footer) {
      footer = this.props.footer;
    }
    return (
      <div
        className={
          "caser-card " +
          (footer ? " caser-card-with-footer" : "caser-card-no-footer")
        }
        {...(this.props.CardProps || {})}
      >
        <div
          className={"caser-card-header"}
          {...(this.props.HeaderProps || {})}
        >
          {header}
        </div>
        <div className={"caser-card-body"} {...(this.props.BodyProps || {})}>
          {this.props.children ? (
            <div
              className={"caser-card-body-inner"}
              {...(this.props.ContentProps || {})}
            >
              {this.props.children}
            </div>
          ) : null}
        </div>
        {footer ? (
          <div
            className={"caser-card-footer"}
            {...(this.props.FooterProps || {})}
          >
            {footer}
          </div>
        ) : null}
      </div>
    );
  }
}
