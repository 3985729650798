import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
export default class Loading extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center"
        }}
      >
        <div style={{ flexGrow: 1 }} />
        <CircularProgress />
        <div style={{ flexGrow: 1 }} />
      </div>
    );
  }
}
