import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import {
  Container,
  TextField,
  Button,
  Card,
  CardActions,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
class Login extends Component {
  static contextType = FeathersContext;
  constructor(props) {
    super(props);
    this.state = {
      examCode: "",
      error: null,
    };
  }

  async componentDidMount() {
    console.log(this.context);
    // console.log(
    //   "GO",
    //   await this.context.app.service("publicexam").create({
    //     func: "updateOldExamResults",
    //   })
    // );
  }
  async handleLoginClick() {
    try {
      let result = await this.context.app.service("publicexam").create({
        func: "checkIfIdCanStartExam",
        params: { examCode: this.state.examCode },
      });

      if (result.result !== true) this.setState({ error: result.description });
      else {
        this.setState({ error: null });
        this.props.goToCurrentPage(this.state.examCode);
      }
      console.log(result);
    } catch (e) {
      console.log(e.message);
      this.setState({ error: e.message });
    }
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Container maxWidth={"xs"} style={{ marginTop: 150 }}>
          <Typography variant="h5" gutterBottom>
            Start the Subdeacon Exam
          </Typography>
          <Card raised style={{ padding: 20 }}>
            <Typography variant="body1" gutterBottom>
              {this.context.LC.val("Enter your exam code below.", "En")}
              <br />
              {this.context.LC.val("Enter your exam code below.", "Ar")}
              <br />
              <br />
              {this.context.LC.val(
                "This is the code given to you by the priest who nominated you. If you do not have a code or lost it, please contact your priest.",
                "En"
              )}
              <br />
              <br />
              {this.context.LC.val(
                "This is the code given to you by the priest who nominated you. If you do not have a code or lost it, please contact your priest.",
                "Ar"
              )}
            </Typography>

            {this.state.error ? (
              <>
                <br />
                <br />
                <MuiAlert severity="error">{this.state.error}</MuiAlert>
                <br />
                <br />
                <div>
                  If you are having a problem with your code, or if you need to
                  resume your exam, please
                  <a href="/contactus"> contact us</a>.
                </div>
              </>
            ) : null}
            <br />
            <br />
            <TextField
              inputProps={{
                style: {
                  fontSize: "40px",
                  width: "200px",
                  color: "blue",
                  textAlign: "center",
                },
                maxLength: 6,
              }}
              autoFocus
              value={this.state.examCode}
              label={
                this.context.LC.val("Exam Code", "En") +
                " - " +
                this.context.LC.val("Exam Code", "Ar")
              }
              color="primary"
              onChange={(event) =>
                this.setState({ examCode: event.target.value })
              }
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              disabled={this.state.examCode.length < 6}
              onClick={(event) => this.handleLoginClick(event)}
            >
              {this.context.LC.val("Submit", "En")} -{" "}
              {this.context.LC.val("Submit", "Ar")}
            </Button>
            <CardActions></CardActions>
          </Card>
        </Container>
      </div>
    );
  }
}

export default Login;
