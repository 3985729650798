import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import { Container, Button, Card, Typography } from "@material-ui/core";
import Stepper from "./Stepper";
export default class LanguageChooser extends Component {
    static contextType = FeathersContext;

    async handleEngishClick() {
        try {
            await this.context.app.service("publicexam").create({
                func: "setLanguage",
                params: { id: this.props.examCode, language: "En" },
            });
            await this.props.goToNextPage();
        } catch (e) {
            console.log(e);
        }
    }
    async handleArabicClick() {
        try {
            await this.context.app.service("publicexam").create({
                func: "setLanguage",
                params: { id: this.props.examCode, language: "Ar" },
            });
            await this.props.goToNextPage();
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <Stepper active={0} />

                <Container maxWidth={"sm"} style={{ marginTop: 150 }}>
                    <Card raised style={{ padding: 20 }}>
                        <Typography variant="h5" gutterBottom>
                            {this.context.LC.val(
                                "Choose Your Exam Language",
                                "En"
                            )}{" "}
                            -{" "}
                            {this.context.LC.val(
                                "Choose Your Exam Language",
                                "Ar"
                            )}
                        </Typography>
                        <br />
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ flexGrow: 1 }}>
                                <Button
                                    onClick={this.handleEngishClick.bind(this)}
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                >
                                    English Language
                                </Button>
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <Button
                                    onClick={this.handleArabicClick.bind(this)}
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                >
                                    اللغة العربية
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Container>
            </div>
        );
    }
}
