import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Button,
  TextField,
  Container,
  List,
  ListItem,
  Checkbox,
  Switch,
  FormControlLabel,
  Box,
  Chip,
  Badge,
  Tabs,
  Tab,
  // InputAdornment,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ButtonGroup,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CaserCard from "../../components/mainComponents/card";
import Collapsable, {
  CollapsablePanel,
} from "../../components/mainComponents/collapsable";
import LeftMenuLayout from "../../components/left-menu-detail";
import { DateTimePicker } from "@material-ui/pickers";
import FeathersContext from "../../feathersContext";
import Loading from "../general/Loading";
import Statuses from "./../exam/statuses.json";

import startCase from "lodash.startcase";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default class Administrations extends React.Component {
  static contextType = FeathersContext;
  state = {
    administrations: [],
    churches: {},
    activeAdministration: null,
  };
  async componentDidMount() {
    this.setState({
      activeAdministration: this.props.match.url.split("/")[3] || null,
    });
    await this.getAdministrations();
    await this.getChurches();
    this.context.app
      .service("administrations")
      .on("patched", this.updateOnPatched);
    this.context.app
      .service("administrations")
      .on("removed", this.updateOnRemoved);
  }

  updateOnPatched = (message) =>
    this.setState((state) => {
      state.administrations[this.getAdministrationIndex(message._id)] = message;
      return state;
    });

  updateOnRemoved = (message) =>
    this.setState((state) => {
      let index = this.getAdministrationIndex(message._id);
      state.administrations.splice(index, 1);
      let id = null;
      if (state.administrations[index]) id = state.administrations[index]._id;
      else if (state.administrations[index - 1])
        id = state.administrations[index - 1]._id;
      else if (state.administrations[index + 1])
        id = state.administrations[index + 1]._id;
      this.setActiveAdministration(id);
      return state;
    });

  async componentWillUnmount() {
    this.context.app
      .service("administrations")
      .off("patched", this.updateOnPatched);
    this.context.app
      .service("administrations")
      .off("removed", this.updateOnRemoved);
  }
  async getAdministrations() {
    try {
      let list = await this.context.app.service("administrations").find();
      list.data.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });

      this.setState({ administrations: list.data });
      if (
        this.state.administrations.length &&
        this.state.activeAdministration === null
      )
        this.setActiveAdministration(this.state.administrations[0]._id);
    } catch (e) {}
  }
  async getChurches() {
    try {
      let list = await this.context.app.service("churches").find();
      list.data = list.data.map((a) => {
        a.label = a.churchName + " " + a.churchCity + ", " + a.churchState;
        return a;
      });
      list.data.sort((a, b) => {
        a = a.label;
        b = b.label;
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      let churchesByCode = list.data.reduce((p, v) => {
        p[v.churchCode] = v;
        return p;
      });

      this.setState({
        churches: list.data.reduce((p, v) => {
          p[v.churchCode] = v;
          return p;
        }, {}),
        churchesByCode,
      });
    } catch (e) {}
  }
  async addAdministration() {
    try {
      let newAdministration = await this.context.app
        .service("administrations")
        .create({});
      console.log("Add", newAdministration);

      await this.getAdministrations();
      this.setActiveAdministration(newAdministration._id);
    } catch (e) {}
  }
  setActiveAdministration(id) {
    this.setState({ activeAdministration: id });
    let url = this.props.match.url.split("/");
    url[3] = id;
    this.props.history.replace(url.join("/"));
  }
  getAdministrationIndex(id = this.state.activeAdministration) {
    for (let [index, administration] of this.state.administrations.entries()) {
      if (administration._id === id) {
        return index;
      }
    }
    return -1;
  }
  render() {
    return (
      <LeftMenuLayout
        leftDrawer={
          <div
            style={{
              background: "#fff",
              overflowY: "scroll",
              // paddingBottom: "20px",
              height: "inherit",
            }}
          >
            <List>
              {this.state.administrations.map((v) => (
                <ListItem
                  button
                  style={{
                    fontWeight: 600,
                    padding: "15px 10px",
                    background:
                      this.state.activeAdministration === v._id
                        ? "#B0BEC5"
                        : "",
                  }}
                  onClick={() => this.setActiveAdministration(v._id)}
                  key={v._id}
                >
                  Exam: {v.id}
                  <br /> &nbsp;&nbsp;
                  {v.title}
                </ListItem>
              ))}
            </List>
          </div>
        }
        mainContent={(anchor) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 0,
                  flexShrink: 0,
                  background: "#546E7A",
                  color: "#fff",
                  padding: 10,
                }}
              >
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                  {anchor} Exam Administrations
                </Typography>
                <Button
                  color="inherit"
                  onClick={this.addAdministration.bind(this)}
                  style={{ flexGrow: 0, fontWeight: "bold" }}
                >
                  New Exam Administration
                </Button>
              </div>
              <div
                style={{
                  background: "#CFD8DC",
                  overflowY: "scroll",
                  paddingBottom: "20px",
                }}
              >
                {this.state.churches ? (
                  <AdministrationDetails
                    churches={this.state.churches}
                    parent={this}
                    id={this.state.activeAdministration}
                  />
                ) : null}
              </div>
            </>
          );
        }}
      />
    );
  }
}

class AdministrationDetails extends React.Component {
  static contextType = FeathersContext;

  state = {
    data: null,
    timewindows: [],
    churches: [],
    questions: [],
    attempts: [],
    attemptsByID: [],
    deleteDialogOpen: false,
    resultsInitialLoading: true,
    resultTab: 0,
  };
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getAdministration();
    }
  }
  async getAdministration() {
    if (this.props.id === null) {
      return;
    }
    this.setState({ data: null });
    try {
      let data = await this.context.app
        .service("administrations")
        .get(this.props.id);
      this.setState({ data });
      await this.getAdministrationTimeWindows();
      await this.getAdministrationResults();
    } catch (e) {
      console.log(e);
    }
  }

  updateField(fieldName, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      this.setState((state) => {
        state.data[fieldName] = value;
        return state;
      });
      let data = {
        [fieldName]: value,
      };
      if (data[fieldName].trim) data[fieldName] = data[fieldName].trim();
      this.updateFieldServer(this.state.data._id, data);
    } catch (e) {
      console.log(e);
    }
  }
  async updateFieldServer(id, data) {
    await this.context.app.service("administrations").patch(id, data);
  }
  updateAttemptField(id, fieldName, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      this.setState((state) => {
        state.attempts.filter((v) => v._id === id)[0][fieldName] = value;
        state.attemptsByID[id][fieldName] = value;
        return state;
      });
      let data = {
        [fieldName]: value,
      };
      if (data[fieldName].trim) data[fieldName] = data[fieldName].trim();
      this.updateAttemptFieldServer(id, data);
    } catch (e) {
      console.log(e);
    }
  }
  async updateAttemptFieldServer(id, data) {
    await this.context.app.service("examattempts").patch(id, data);
  }
  async removeAdministration() {
    if (this.props.id === null) {
      return;
    }
    try {
      await this.context.app.service("administrations").remove(this.props.id);

      this.setState({ deleteDialogOpen: false });
    } catch (e) {
      console.log(e);
    }
  }
  async addTimeWindow() {
    try {
      await this.context.app
        .service("timewindow")
        .create({ administrationId: this.props.id });
      await this.getAdministrationTimeWindows();
    } catch (e) {}
  }
  async getAdministrationTimeWindows() {
    if (this.props.id === null) {
      return;
    }
    this.setState({ timewindows: [] });
    try {
      let timewindows = await this.context.app
        .service("timewindow")
        .find({ query: { administrationId: this.props.id } });
      this.setState({ timewindows: timewindows.data });
    } catch (e) {
      console.log(e);
    }
  }
  async getAdministrationResults() {
    if (this.props.id === null) {
      return;
    }
    this.setState({ attempts: [] });
    try {
      let attempts = await this.context.app
        .service("examattempts")
        .find({ query: { examCode: this.state.data.id } });

      //Add Questions index to the attempt;
      attempts.data = attempts.data.map((a) => {
        a.age = moment((this.state.timewindows[0] || {}).startDateTime).diff(
          a.dob,
          "years",
          false
        );
        // a.questions.map((q, i) => {
        //   q.i = i;
        //   return q;
        // });
        return a;
      });

      // console.log(attempts.data);
      attempts.byID = attempts.data.reduce((r, v) => {
        r[v._id] = v;
        return r;
      }, {});
      let churches = attempts.data
        .reduce((p, v) => {
          if (!p.includes(v.churchCode)) p.push(v.churchCode);
          return p;
        }, [])
        .map((v) => {
          let res = this.props.churches[v];
          res.attempts = attempts.data.filter(
            (r) => r.churchCode === res.churchCode
          );

          return res;
        });
      let qIDs = Object.keys(
        attempts.data.reduce((r, v) => {
          Object.values(v.questions).forEach((q) => {
            r[q.id] = true;
          });
          return r;
        }, {})
      );
      // console.log(qIDs);
      let questions = await this.context.app.service("questions").find({
        query: {
          $limit: 300,
        },
      });
      questions.data = questions.data
        .filter((v) => qIDs.includes(v._id))
        .map((q) => {
          let qAttempts = attempts.data.reduce((full, at) => {
            //Get Question Attempts
            for (let qu in at.questions) {
              if (at.questions[qu].id === q._id) {
                full[at._id] = qu;
                break;
              }
            }
            return full;
          }, {});

          // console.log(qAttempts);
          //Get Percent Correct
          let correctArray = [];
          q.attempts = [];
          for (let a of attempts.data) {
            if (typeof qAttempts[a._id] !== "undefined") {
              q.attempts.push(a._id);
              correctArray.push(a.questions[qAttempts[a._id]].correct ? 1 : 0);
            }
          }
          q.average =
            (correctArray.reduce((p, v) => p + v, 0) / correctArray.length) *
            100;
          if (q.average < 100) q.average = q.average.toFixed(1);

          //Get Choices Info
          q.results = {};

          // console.log(qAttempts)
          for (let choice in q.Choices.En) {
            q.results[choice] = [];
            for (let aId in qAttempts) {
              let attempt = attempts.byID[aId];
              let attemptQ = attempt.questions[qAttempts[aId]];
              if (attemptQ.selectedAnswer === choice)
                q.results[choice].push(attempt._id);
            }
          }

          return q;
        })
        .sort((a, b) => b.average - a.average);

      attempts.data
        .reduce((p, v) => {
          if (!p.includes(v.churchCode)) p.push(v.churchCode);
          return p;
        }, [])
        .map((v) => {
          let res = this.props.churches[v];
          res.attempts = attempts.data.filter(
            (r) => r.churchCode === res.churchCode
          );

          return res;
        });

      this.setState({
        attempts: attempts.data,
        attemptsByID: attempts.byID,
        churches,
        questions: questions.data,
        resultsInitialLoading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    console.log(this.state);
    if (this.props.id === null)
      return <div>Select a Administration to edit the details.</div>;
    else if (this.state.data === null) {
      return null;
    } else {
      return (
        <div>
          <Container maxWidth="md">
            <CaserCard
              style={{ padding: "20px", textAlign: "center" }}
              header={"Administration Details"}
              footer={
                <Button
                  color="secondary"
                  size={"small"}
                  onClick={() =>
                    this.setState({
                      deleteDialogOpen: true,
                    })
                  }
                >
                  Delete
                </Button>
              }
            >
              <TextField
                fullWidth
                label="Exam ID"
                value={this.state.data.id}
                onChange={(e) => {
                  this.updateField("id", e.target.value);
                }}
              />
              <br />
              <br />
              <TextField
                fullWidth
                label="Exam Title"
                value={this.state.data.title}
                onChange={(e) => {
                  this.updateField("title", e.target.value);
                }}
              />

              <Typography variant="caption">
                Database ID: {this.state.data._id}
              </Typography>
            </CaserCard>
            <CaserCard
              header={"Exam Time Windows"}
              footer={
                <Button color="primary" onClick={this.addTimeWindow.bind(this)}>
                  New Time Window
                </Button>
              }
            >
              {this.state.timewindows.map((v) => (
                <TimeWindow parent={this} id={v._id} key={v._id} />
              ))}
            </CaserCard>

            <CaserCard header={"Exam Results"}>
              {this.state.resultsInitialLoading ? (
                <Loading />
              ) : (
                <>
                  <Tabs
                    variant={"fullWidth"}
                    value={this.state.resultTab}
                    onChange={(e, v) => this.setState({ resultTab: v })}
                  >
                    <Tab
                      label={
                        <Badge
                          max={999}
                          badgeContent={this.state.attempts.length}
                          color={"primary"}
                        >
                          Attempts
                        </Badge>
                      }
                    ></Tab>
                    <Tab
                      label={
                        <Badge
                          max={999}
                          badgeContent={this.state.churches.length}
                          color={"primary"}
                        >
                          Churches
                        </Badge>
                      }
                    ></Tab>

                    <Tab
                      label={
                        <Badge
                          max={999}
                          badgeContent={this.state.questions.length}
                          color={"primary"}
                        >
                          Questions
                        </Badge>
                      }
                    ></Tab>
                    <Tab
                      label={
                        // <Badge
                        //   max={999}
                        //   badgeContent={this.state.questions.length}
                        //   color={"primary"}
                        // >
                        "Cheating Analysis"
                        // </Badge>
                      }
                    ></Tab>
                  </Tabs>
                  <TabPanel value={this.state.resultTab} index={0}>
                    <Collapsable>
                      {this.state.attempts.map((r) => (
                        <ExamResult
                          data={r}
                          parent={this}
                          key={r._id}
                        ></ExamResult>
                      ))}
                    </Collapsable>
                  </TabPanel>
                  <TabPanel value={this.state.resultTab} index={1}>
                    <Collapsable>
                      {this.state.churches.map((c) => (
                        <CollapsablePanel
                          key={c._id}
                          renderClosed={false}
                          header={
                            <Box display={"flex"} flexDirection="row">
                              {c.churchName} {c.churchCity}, {c.churchState}
                              <Box flexGrow={1} />
                              <Chip
                                color={"primary"}
                                label={"Total " + c.attempts.length}
                              ></Chip>
                            </Box>
                          }
                        >
                          <Collapsable>
                            {c.attempts.map((r) => (
                              <ExamResult
                                data={r}
                                parent={this}
                                key={r._id}
                              ></ExamResult>
                            ))}
                          </Collapsable>
                        </CollapsablePanel>
                      ))}
                    </Collapsable>
                  </TabPanel>
                  <TabPanel value={this.state.resultTab} index={2}>
                    <Collapsable>
                      {this.state.questions.map((q, qi) => (
                        <CollapsablePanel
                          key={qi}
                          renderClosed={false}
                          header={
                            <Box width={"100%"} display={"flex"}>
                              <Box flexGrow={1} flexShrink={1}>
                                <Typography
                                  variant={"body1"}
                                  width="100%"
                                  align="left"
                                  style={{ flexGrow: 1, flexShrink: 1 }}
                                >
                                  {qi + 1}: {q.Question.En}
                                </Typography>
                              </Box>
                              <Chip
                                color={"primary"}
                                label={q.average + "% / " + q.attempts.length}
                              ></Chip>
                            </Box>
                          }
                        >
                          <Button
                            href={"/admin/questions/" + q._id}
                            color={"primary"}
                            variant={"outlined"}
                          >
                            Go to Question
                          </Button>
                          {Object.entries(q.Choices.En).map(
                            ([Letter, Text]) => (
                              <CaserCard
                                key={q._id}
                                renderClosed={false}
                                headerProps={{
                                  style: {
                                    background:
                                      q.Answer === Letter
                                        ? "#C8E6C9"
                                        : "#ffcdd2",
                                  },
                                }}
                                header={
                                  <Box width={"100%"} display={"flex"}>
                                    <Box flexGrow={1} flexShrink={1}>
                                      <Typography
                                        variant={"body1"}
                                        width="100%"
                                        align="left"
                                        style={{ flexGrow: 1, flexShrink: 1 }}
                                      >
                                        {Letter}) {Text}
                                      </Typography>{" "}
                                    </Box>

                                    <Chip
                                      color={"primary"}
                                      label={
                                        (
                                          (q.results[Letter].length /
                                            q.attempts.length) *
                                          100
                                        ).toFixed(1) +
                                        "% (" +
                                        q.results[Letter].length +
                                        "/" +
                                        q.attempts.length +
                                        ")"
                                      }
                                    ></Chip>
                                    {/* <Chip color={"primary"} label={"Total "+c.attempts.length}></Chip> */}
                                  </Box>
                                }
                              >
                                <Box padding={"10px"} width="100%">
                                  <Collapsable>
                                    {q.results[Letter].map((ch, i) => (
                                      <ExamResult
                                        data={this.state.attemptsByID[ch]}
                                        parent={this}
                                        key={i}
                                      ></ExamResult>
                                    ))}
                                  </Collapsable>
                                </Box>
                              </CaserCard>
                            )
                          )}

                          {/* {c.attempts.map((r) => (
   <ExamResult data={r} parent={this}></ExamResult>
))} */}
                        </CollapsablePanel>
                      ))}
                    </Collapsable>
                  </TabPanel>
                  <TabPanel value={this.state.resultTab} index={3}>
                    {/* <Collapsable> */}
                    {() => {
                      let correct_answers = this.state.questions.reduce(
                        (a, v) => {
                          a[v._id] = v["Correct Answer"];

                          return a;
                        },
                        {}
                      );
                      let churchesByCode = this.state.churches.reduce(
                        (a, v) => {
                          a[v.churchCode] = v;

                          return a;
                        },
                        {}
                      );

                      let candidates = this.state.attempts.reduce((a, v) => {
                        a[v._id] = v;
                        a[v._id].qs = Object.values(a[v._id].questions).reduce(
                          (a, v) => {
                            a[v.id] = v;

                            return a;
                          },
                          {}
                        );
                        a[v._id].answers = {};
                        for (let qID in correct_answers) {
                          if (v.qs[qID])
                            a[v._id].answers[qID] = v.qs[qID]["selectedAnswer"];
                          else a[v._id].answers[qID] = "No answer";
                        }
                        // console.log(a);
                        return a;
                      }, {});
                      let connections = [];
                      for (let first in candidates) {
                        for (let second in candidates) {
                          if (first < second) {
                            if (!connections.includes(first + "-" + second))
                              connections.push(first + "-" + second);
                          } else if (first > second) {
                            if (!connections.includes(second + "-" + first))
                              connections.push(second + "-" + first);
                          }
                        }
                      }
                      // console.log("collusion", correct_answers, connections);
                      let algo = (
                        first,
                        second,
                        candidates,
                        correct_answers
                      ) => {
                        first = candidates[first];
                        second = candidates[second];
                        let res = 1;
                        for (let q in correct_answers) {
                          // console.log();
                          if (
                            !(
                              first.answers[q] === "No answer" ||
                              second.answers[q] === "No answer"
                            ) &&
                            (first.answers[q] !== correct_answers[q] ||
                              second.answers[q] !== correct_answers[q]) &&
                            first.answers[q] !== second.answers[q]
                          ) {
                            res = res - 0.02;
                          }
                        }
                        // console.log(res);
                        return res;
                      };
                      // console.log(connections.length * 95);
                      // var total = 0;
                      var res = [];
                      // console.time("calcs");
                      for (let c of connections) {
                        c = c.split("-");
                        // c[0] = parseInt(c[0], 10);
                        // c[1] = parseInt(c[1], 10);
                        let result = algo(
                          c[0],
                          c[1],
                          candidates,
                          correct_answers
                        );
                        // total += result;
                        // res += Math.round(result * 100) + "\n";
                        // console.log(Math.round(result * 100));
                        if (result > 0.96) {
                          // console.log(
                          //   candidates[c[0]]["firstName"] +
                          //     " " +
                          //     candidates[c[0]]["lastName"] +
                          //     " (" +
                          //     candidates[c[0]].results.percentage +
                          //     ") from " +
                          //     churchesByCode[candidates[c[0]]["churchCode"]]
                          //       .churchName +
                          //     churchesByCode[candidates[c[0]]["churchCode"]]
                          //       .churchCity +
                          //     churchesByCode[candidates[c[0]]["churchCode"]]
                          //       .churchState +
                          //     " and " +
                          //     candidates[c[1]]["firstName"] +
                          //     " " +
                          //     candidates[c[1]]["lastName"] +
                          //     " (" +
                          //     candidates[c[1]].results.percentage +
                          //     ") from " +
                          //     churchesByCode[candidates[c[1]]["churchCode"]]
                          //       .churchName +
                          //     churchesByCode[candidates[c[1]]["churchCode"]]
                          //       .churchCity +
                          //     churchesByCode[candidates[c[1]]["churchCode"]]
                          //       .churchState +
                          //     " are similar by " +
                          //     result * 100 +
                          //     "%"
                          // );
                          res.push([c[0], c[1], result]);
                        }
                      }
                      return (
                        <Collapsable>
                          {res
                            .sort((a, b) => b[2] - a[2])
                            .map((c) => (
                              <CollapsablePanel
                                key={c[0] + "-" + c[1]}
                                renderClosed={false}
                                header={
                                  <Box display={"flex"} flexDirection="row">
                                    <Box
                                      display={"flex"}
                                      flexDirection="column"
                                    >
                                      <div>
                                        <b>
                                          {candidates[c[0]]["firstName"] +
                                            " " +
                                            candidates[c[0]]["lastName"]}
                                        </b>
                                        {" (" +
                                          candidates[c[0]].results.percentage +
                                          ") from " +
                                          churchesByCode[
                                            candidates[c[0]]["churchCode"]
                                          ].churchName +
                                          " in " +
                                          churchesByCode[
                                            candidates[c[0]]["churchCode"]
                                          ].churchCity +
                                          " " +
                                          churchesByCode[
                                            candidates[c[0]]["churchCode"]
                                          ].churchState}
                                      </div>
                                      <div>
                                        <b>
                                          {candidates[c[1]]["firstName"] +
                                            " " +
                                            candidates[c[1]]["lastName"]}
                                        </b>
                                        {" (" +
                                          candidates[c[1]].results.percentage +
                                          ") from " +
                                          churchesByCode[
                                            candidates[c[1]]["churchCode"]
                                          ].churchName +
                                          " in " +
                                          churchesByCode[
                                            candidates[c[1]]["churchCode"]
                                          ].churchCity +
                                          " " +
                                          churchesByCode[
                                            candidates[c[1]]["churchCode"]
                                          ].churchState}
                                      </div>
                                    </Box>
                                    <Box flexGrow={1} />
                                    <Chip
                                      color={"primary"}
                                      label={c[2] * 100 + "%"}
                                    ></Chip>
                                  </Box>
                                }
                              >
                                <Collapsable>
                                  <ExamResult
                                    data={this.state.attempts.find(
                                      (v) => v._id === c[0]
                                    )}
                                    parent={this}
                                    key={c[0]}
                                  ></ExamResult>
                                  <ExamResult
                                    data={this.state.attempts.find(
                                      (v) => v._id === c[1]
                                    )}
                                    parent={this}
                                    key={c[1]}
                                  ></ExamResult>
                                </Collapsable>
                              </CollapsablePanel>
                            ))}
                        </Collapsable>
                      );
                      // console.timeEnd("calcs");
                      // console.log(total / connections.length);
                      // console.log(res);
                    }}
                    {/* </Collapsable> */}
                  </TabPanel>
                </>
              )}
            </CaserCard>
            <CaserCard header={"Exam Email Notifications"}>
              <ChurchNotification
                template={"instructions"}
                adminId={this.state.data._id}
                churches={this.props.churches}
                name={"Instructions and Exam Numbers"}
              />
              <br />
              <br />
              <ChurchNotification
                template={"reminder"}
                adminId={this.state.data._id}
                churches={this.props.churches}
                name={"Reminder Email"}
              />
              <br />
              <br />
              <ChurchNotification
                template={"results"}
                adminId={this.state.data._id}
                churches={this.state.churches}
                participating
                name={"Exam Results"}
              />
            </CaserCard>
          </Container>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            open={this.state.deleteDialogOpen}
          >
            <DialogTitle id="confirmation-dialog-title">
              Are you sure?
            </DialogTitle>
            <DialogContent>
              Are you sure you want to permenantly delete this administration
              from the database?
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => this.setState({ deleteDialogOpen: false })}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                onClick={this.removeAdministration.bind(this)}
                color="primary"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
}

class ExamResult extends React.Component {
  state = {
    language: "En",
  };
  addScoreModification = async () => {
    console.log(this.props);
    if (!this.props.data) {
      return;
    }
    let scoreModifications = this.props.data.scoreModifications || [];
    scoreModifications.push({});
    console.log(this.props.data._id, "scoreModifications", scoreModifications);
    await this.props.parent.updateAttemptField(
      this.props.data._id,
      "scoreModifications",
      scoreModifications
    );
    console.log();
  };
  patchScoreModification = async (index, data) => {
    if (!this.props.data) {
      return;
    }
    let scoreModifications = this.props.data.scoreModifications || [];
    scoreModifications[index] = Object.assign(scoreModifications[index], data);
    await this.props.parent.updateAttemptField(
      this.props.data._id,
      "scoreModifications",
      scoreModifications
    );
  };
  removeScoreModification = async (index) => {
    if (
      !this.props.data ||
      !Array.isArray(this.props.data.scoreModifications) ||
      !this.props.data.scoreModifications[index]
    ) {
      return;
    }
    let scoreModifications = this.props.data.scoreModifications;
    scoreModifications.splice(index, 1);
    await this.props.parent.updateAttemptField(
      this.props.data._id,
      "scoreModifications",
      scoreModifications
    );
  };
  getStatus() {
    if (!this.props.data) {
      return "";
    }
    let r = this.props.data;
    if (r.status === "complete") {
      if (!r.results) return "Not Scored";
      let score = this.getFinalScore().result;
      return score;
    }
    if (r.status === "exam") return "In Progress";
    if (r.status === "certification") return "Certifying";
    else return "Preparing";
  }
  indexToLetter(i) {
    let index = "ABCDEFGHIJKLMNOP";
    return index[i];
  }
  letterToIndex(i) {
    let index = "ABCDEFGHIJKLMNOP";
    return index.indexOf(i);
  }

  getFinalScore() {
    if (!this.props.data) {
      return { points: "", result: "" };
    }

    let points = parseInt(this.props.data.results.points_scored, 10);
    let result = points >= 40 ? "Passed" : "Did Not Pass";
    for (let mod of this.props.data.scoreModifications || []) {
      if (!mod.type) continue;
      if (mod.type === "+Points") {
        points = points + (mod.points || 0);
        result = points >= 40 ? "Passed" : "Did Not Pass";
      } else if (mod.type === "-Points") {
        points = points - (mod.points || 0);
        result = points >= 40 ? "Passed" : "Did Not Pass";
      } else if (mod.type === "Pass") {
        result = "Passed";
      } else if (mod.type === "Fail") {
        result = "Did Not Pass";
      }
    }
    return { points, result };
  }
  render() {
    let r = this.props.data;
    return (
      <CollapsablePanel
        {...this.props}
        renderClosed={false}
        header={
          <Box display="flex" width="100%">
            <Chip label={r._id} style={{ marginRight: "5px" }}></Chip>
            <Typography style={{ flexGrow: 1 }} align={"left"} variant="h6">
              {r.firstName} {r.lastName}
            </Typography>
            <Box flexGrow={0}>
              {r.age > 59 ? (
                <Chip color={"default"} label={"60+"}></Chip>
              ) : null}
              <Chip
                color={
                  this.getStatus() === "Passed"
                    ? "primary"
                    : this.getStatus() === "Did Not Pass"
                    ? "secondary"
                    : "default"
                }
                label={this.getStatus()}
              ></Chip>
              {r.examCompleted && r.certified !== true ? (
                <Chip color="secondary" label={"Did not Certify"}></Chip>
              ) : null}
            </Box>
          </Box>
        }
      >
        {
          <Box textAlign="left">
            <CaserCard header={"Candidate Information"}>
              <TextField
                fullWidth
                label="First Name"
                value={r.firstName}
                onChange={(e) => {
                  this.props.parent.updateAttemptField(
                    r._id,
                    "firstName",
                    e.target.value
                  );
                }}
              />
              <TextField
                fullWidth
                label="Middle Name"
                value={r.middleName}
                onChange={(e) => {
                  this.props.parent.updateAttemptField(
                    r._id,
                    "middleName",
                    e.target.value
                  );
                }}
              />
              <TextField
                fullWidth
                label="Last Name"
                value={r.lastName}
                onChange={(e) => {
                  this.props.parent.updateAttemptField(
                    r._id,
                    "lastName",
                    e.target.value
                  );
                }}
              />
              <TextField
                fullWidth
                label="Phone Number"
                value={r.phoneNumber}
                onChange={(e) => {
                  this.props.parent.updateAttemptField(
                    r._id,
                    "phoneNumber",
                    e.target.value
                  );
                }}
              />
              <TextField
                fullWidth
                label="Email Address"
                value={r.emailAddress}
                onChange={(e) => {
                  this.props.parent.updateAttemptField(
                    r._id,
                    "emailAddress",
                    e.target.value
                  );
                }}
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  fullWidth
                  openTo="year"
                  variant="inline"
                  format="MM/DD/YYYY"
                  margin="normal"
                  label={"Date of Birth (MM/DD/YYYY)"}
                  value={r.dob}
                  onChange={(e) => {
                    this.props.parent.updateAttemptField(
                      r._id,
                      "dob",
                      e.target.value
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
              <TextField fullWidth label="Age" value={r.age} readOnly />
              {this.props.parent.props.parent.state.churches[r.churchCode] ? (
                <TextField
                  fullWidth
                  label="Church"
                  value={
                    this.props.parent.props.parent.state.churches[r.churchCode]
                      .label
                  }
                  readOnly
                />
              ) : null}
            </CaserCard>
            <CaserCard header={"Exam Information"}>
              <Select
                fullWidth
                label={"Exam Status"}
                value={r.status}
                onChange={(e) => {
                  this.props.parent.updateAttemptField(
                    r._id,
                    "status",
                    e.target.value
                  );
                }}
              >
                {Statuses.map((v) => (
                  <MenuItem value={v} key={v}>
                    {startCase(v)}
                  </MenuItem>
                ))}
              </Select>
              <Select
                fullWidth
                label={"Exam Language"}
                value={r.language}
                onChange={(e) => {
                  this.props.parent.updateAttemptField(
                    r._id,
                    "language",
                    e.target.value
                  );
                }}
              >
                <MenuItem value={"En"}>English</MenuItem>
                <MenuItem value={"Ar"}>Arabic</MenuItem>
              </Select>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  readOnly
                  fullWidth
                  // openTo="year"
                  variant="inline"
                  // format="MM/DD/YYYY"
                  margin="normal"
                  label={"Exam Start Time"}
                  value={r.examStartTime}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  fullWidth
                  // openTo="year"
                  variant="inline"
                  // format="MM/DD/YYYY"
                  onChange={(dt) =>
                    this.props.parent.updateAttemptField(
                      r._id,
                      "examEndTime",
                      moment(dt).toISOString()
                    )
                  }
                  margin="normal"
                  label={"Exam End Time"}
                  value={r.examEndTime}
                />
              </MuiPickersUtilsProvider>
            </CaserCard>
            {r.results ? (
              <>
                <CaserCard header={"Score"}>
                  <b>Raw Points (Out of 50): </b>
                  {r.results.points_scored} (
                  {r.results.points_scored > 39 ? "Passed" : "Did Not Pass"})
                  {(r.scoreModifications || []).map((mod, modi) => (
                    <Box display={"flex"} key={modi}>
                      <Box flexGrow={1} flexShrink={1}>
                        <FormControl fullWidth variant={"filled"}>
                          <InputLabel>Type</InputLabel>
                          <Select
                            value={mod.type}
                            onChange={(e) => {
                              this.patchScoreModification(modi, {
                                type: e.target.value,
                              });
                            }}
                          >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value={"+Points"}>Add Points</MenuItem>
                            <MenuItem value={"-Points"}>
                              Subtract Points
                            </MenuItem>
                            <MenuItem value={"Pass"}>Pass</MenuItem>
                            <MenuItem value={"Fail"}>Not Pass</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      {mod.type === "+Points" || mod.type === "-Points" ? (
                        <Box flexGrow={1} flexShrink={1}>
                          <TextField
                            fullWidth
                            type={"number"}
                            variant={"filled"}
                            label={"Points"}
                            value={mod.points || 0}
                            onChange={(e) => {
                              this.patchScoreModification(modi, {
                                points: parseInt(e.target.value),
                              });
                            }}
                          ></TextField>
                        </Box>
                      ) : null}
                      <Box flexGrow={2} flexShrink={2}>
                        <TextField
                          fullWidth
                          variant={"filled"}
                          label={"Description"}
                          value={mod.note}
                          onChange={(e) => {
                            this.patchScoreModification(modi, {
                              note: e.target.value,
                            });
                          }}
                        ></TextField>
                      </Box>
                      <Box flexGrow={0} flexShrink={0}>
                        <Button
                          color={"secondary"}
                          variant={"outlined"}
                          onClick={() => this.removeScoreModification(modi)}
                        >
                          -
                        </Button>
                      </Box>
                    </Box>
                  ))}
                  <br />
                  <Button color={"primary"} onClick={this.addScoreModification}>
                    Add Modification
                  </Button>
                  <br />
                  <b>Final Points (Out of 50): </b>
                  {this.getFinalScore().points} ({this.getFinalScore().result})
                </CaserCard>
                <CaserCard
                  header={
                    <Box display={"flex"} flexDirection={"row"}>
                      Questions
                      <Box flexGrow={1} />
                      <ButtonGroup>
                        <Button
                          color={
                            this.state.language === "En" ? "primary" : "default"
                          }
                          onClick={() => this.setState({ language: "En" })}
                          variant="contained"
                        >
                          English
                        </Button>
                        <Button
                          color={
                            this.state.language === "Ar" ? "primary" : "default"
                          }
                          onClick={() => this.setState({ language: "Ar" })}
                          variant="contained"
                        >
                          Arabic
                        </Button>
                      </ButtonGroup>
                    </Box>
                  }
                >
                  <div dir={this.state.language === "Ar" ? "rtl" : "ltr"}>
                    {Object.entries(this.props.data.questions).map(
                      ([qNumber, q]) => {
                        let qText = this.props.parent.state.questions.filter(
                          (qT) => qT._id === q.id
                        )[0];
                        return (
                          <CaserCard
                            header={"Question " + qNumber}
                            key={qNumber}
                          >
                            <div
                              style={{
                                textAlign: "start",
                                fontSize: "120%",
                                fontWeight: 800,
                              }}
                            >
                              {qText.Question[this.state.language]}
                            </div>

                            {Object.entries(
                              qText.Choices[this.state.language]
                            ).map(([i, v]) => (
                              <Box
                                key={v}
                                style={{
                                  fontWeight: "800",
                                  textAlign: "start",
                                  margin: "0px",
                                  padding: "20px",
                                  cursor: "pointer",
                                  width: "inherit",
                                  borderRadius: "5px",
                                  border: "solid 3px",
                                  borderColor:
                                    qText.Answer === i ? "green" : "#fff",
                                  background:
                                    q.selectedAnswer === i ? "#ccc" : "#fff",
                                }}
                              >
                                {i}
                                {": "}
                                {v}
                              </Box>
                            ))}
                          </CaserCard>
                        );
                      }
                    )}
                  </div>
                </CaserCard>
              </>
            ) : null}
          </Box>
        }
      </CollapsablePanel>
    );
  }
}

function ChurchNotification(props) {
  let [options, setOptions] = React.useState([]);
  let [isLoading, setLoading] = React.useState(false);
  let feathers = React.useContext(FeathersContext);
  let onSend = async () => {
    setLoading(true);
    let createData = {
      template: props.template,
      administrationId: props.adminId,
      churchIds: options.map((v) => v._id),
    };
    console.log(createData);
    try {
      await feathers.app.service("notifications").create(createData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <CaserCard
        header={props.name}
        footer={
          <Box display="flex" flexDirection="row">
            {props.participating ? (
              <Button
                color={"secondary"}
                onClick={() => setOptions(Object.values(props.churches))}
              >
                Include Participating
              </Button>
            ) : (
              <Button
                color={"secondary"}
                onClick={() => setOptions(Object.values(props.churches))}
              >
                Include All
              </Button>
            )}
            <Button color={"secondary"} onClick={() => setOptions([])}>
              Include None
            </Button>{" "}
            <Box flexGrow={1} />
            <Button
              color={"primary"}
              variant={"contained"}
              size={"large"}
              disabled={isLoading}
              onClick={onSend}
            >
              {isLoading ? "Sending..." : "Send Email Notification"}
            </Button>
          </Box>
        }
      >
        <Autocomplete
          multiple
          value={options}
          onChange={(e, o) => {
            setOptions(o);
          }}
          options={Object.values(props.churches)}
          disableCloseOnSelect
          getOptionLabel={(option) =>
            option.churchName +
            " " +
            option.churchCity +
            ", " +
            option.churchState
          }
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.churchName +
                " " +
                option.churchCity +
                ", " +
                option.churchState}
            </React.Fragment>
          )}
          // style={{ width: 500 }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Choose Churches to Send Email"
              placeholder="Churches"
            />
          )}
        />
      </CaserCard>
    </Container>
  );
}

class TimeWindow extends React.Component {
  static contextType = FeathersContext;

  state = { data: null, deleteDialogOpen: false, moveDialogOpen: false };
  componentDidMount() {
    this.getTimeWindow();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getTimeWindow();
    }
  }
  async getTimeWindow() {
    if (this.props.id === null) {
      return;
    }
    this.setState({ data: null });
    try {
      let data = await this.context.app
        .service("timewindow")
        .get(this.props.id);
      this.setState({ data });
    } catch (e) {
      console.log(e);
    }
  }
  async updateField(fieldName, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      let data = {
        // _id: this.state.data._id,
        [fieldName]: value,
      };
      this.setState({ data: Object.assign(this.state.data, data) });
      // data[fieldName] = data[fieldName].trim();
      await this.context.app
        .service("timewindow")
        .patch(this.state.data._id, data);
    } catch (e) {
      console.log(e);
    }
  }
  async removeTimeWindow() {
    if (this.props.id === null) {
      return;
    }
    try {
      await this.context.app.service("timewindow").remove(this.props.id);
      await this.props.parent.getAdministrationTimeWindows();
      this.setState({ deleteDialogOpen: false });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    if (this.props.id === null) return null;
    else if (this.state.data === null) {
      return <div></div>;
    } else {
      return (
        <div>
          <Container maxWidth="md">
            <CaserCard
              header={"Time Window"}
              footer={
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.data.published}
                        onChange={(event) => {
                          this.updateField("published", event.target.checked);
                        }}
                        name="Published"
                      />
                    }
                    label="Published"
                    style={{ color: "#000" }}
                  />
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() =>
                      this.setState({
                        deleteDialogOpen: true,
                      })
                    }
                  >
                    Delete
                  </Button>
                </div>
              }
            >
              <Box display="flex">
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    fullWidth
                    autoOk
                    format="lll"
                    margin="dense"
                    label="Start"
                    value={this.state.data.startDateTime}
                    onChange={(value) => {
                      this.updateField("startDateTime", value);
                    }}
                  />
                  <DateTimePicker
                    fullWidth
                    autoOk
                    format="lll"
                    margin="dense"
                    label="End"
                    value={this.state.data.endDateTime}
                    onChange={(value) => {
                      this.updateField("endDateTime", value);
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </CaserCard>

            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
              open={this.state.deleteDialogOpen}
            >
              <DialogTitle id="confirmation-dialog-title">
                Are you sure?
              </DialogTitle>
              <DialogContent>
                Are you sure you want to permenantly delete this exam time
                window from the database?
              </DialogContent>
              <DialogActions>
                <Button
                  autoFocus
                  onClick={() =>
                    this.setState({
                      deleteDialogOpen: false,
                    })
                  }
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.removeTimeWindow.bind(this)}
                  color="primary"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
        </div>
      );
    }
  }
}
