import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import {
  Container,
  Button,
  Card,
  Typography,
  // TextField,
  // Select,
  // MenuItem,
  CardActions,
} from "@material-ui/core";
// import MomentUtils from "@date-io/moment";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import moment from "moment";
import Stepper from "./Stepper";

export default class CandidateInformation extends Component {
  static contextType = FeathersContext;
  state = {
    firstName: "",
    middleName: "",
    lastName: "",
    dob: null,
    nominatingPriest: "",
    nominatingPriestOther: "",
    churchFathers: [],
  };
  async componentDidMount() {
    try {
      let language = await this.context.app.service("publicexam").create({
        func: "getLanguage",
        params: { examCode: this.props.examCode },
      });
      this.context.LC.setLanguage(language);
      this.setState({ language });
      let church = await this.context.app.service("publicexam").create({
        func: "getChurchFathers",
        params: { examCode: this.props.examCode },
      });
      this.setState({ churchFathers: church.fathers });
    } catch (e) {
      console.log(e);
    }
  }
  startExam = async () => {
    await this.context.app.service("publicexam").create({
      func: "startExam",
      params: {
        examCode: this.props.examCode,
      },
    });

    await this.props.goToNextPage();
  };
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Stepper
          active={2}
          languageChooserClick={this.props.goToPreviousPage}
        />
        <Container maxWidth={"md"} style={{ marginTop: 150 }}>
          <Card raised style={{ padding: 20 }}>
            <div
              style={{ textAlign: "start" }}
              dir={this.state.language === "Ar" ? "rtl" : "ltr"}
            >
              <Typography variant="h5" gutterBottom>
                {this.context.LC.val("Exam Instructions")}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <ul>
                  <li>
                    <strong>
                      {this.context.LC.val(
                        "This exam is closed book. You cannot use any materials to aid you during the exam."
                      )}
                    </strong>
                  </li>
                  <li>
                    <strong>
                      {this.context.LC.val(
                        "If you encounter any technical issues during this exam, you are required to reach out to us immediately at admin@susdeacons.com."
                      )}
                    </strong>
                  </li>
                  <li>
                    {this.context.LC.val("Number of questions:")}
                    <strong> 50</strong>
                  </li>
                  <li>
                    {this.context.LC.val("Has a time limit of:")}
                    <strong> 01:00:00</strong>
                  </li>
                  <li>
                    {this.context.LC.val(
                      "Must be finished in one sitting. You cannot save and finish later."
                    )}
                  </li>
                  <li>
                    {this.context.LC.val("Questions displayed per page:")}
                    <strong> 1</strong>
                  </li>
                  <li>
                    {this.context.LC.val(
                      "Will allow you to go back and change your answers."
                    )}
                  </li>
                  <li>
                    {this.context.LC.val(
                      "Will not let you finish with any questions unattempted."
                    )}
                  </li>
                  <li>
                    {this.context.LC.val("Has a pass mark of:")}
                    <strong> {"80%"} </strong>
                  </li>
                </ul>
              </Typography>
              <CardActions>
                <Button
                  variant={"contained"}
                  type="submit"
                  color={"primary"}
                  onClick={this.startExam}
                >
                  {this.context.LC.val("Agree and Start Exam")}
                </Button>
              </CardActions>
            </div>
          </Card>
        </Container>
      </div>
    );
  }
}
