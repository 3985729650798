import React from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import FeathersContext from "../../feathersContext";

export default function PageStepper(props) {
    let context = React.useContext(FeathersContext);
    return (
        <div style={{ textAlign: "center" }}>
            <Stepper activeStep={props.active}>
                <Step
                    onClick={() => {
                        if (props.languageChooserClick)
                            props.languageChooserClick();
                    }}
                >
                    <StepLabel>{context.LC.val("Language Chooser")}</StepLabel>
                </Step>
                <Step
                    onClick={() => {
                        if (props.CandidateInformationClick)
                            props.CandidateInformationClick();
                    }}
                >
                    <StepLabel>
                        {context.LC.val("Candidate Information")}
                    </StepLabel>
                </Step>
                <Step
                    onClick={() => {
                        if (props.ExamInstructionsClick)
                            props.ExamInstructionsClick();
                    }}
                >
                    <StepLabel>{context.LC.val("Exam Instructions")}</StepLabel>
                </Step>
                {/* <Step>
                    <StepLabel>{"Exam"}</StepLabel>
                </Step>
                <Step>
                    <StepLabel>{"Certification"}</StepLabel>
                </Step> */}
            </Stepper>
        </div>
    );
}
