import React from "react";

export default class Collapsable extends React.Component {
  render() {
    var elements = this.props.children || [];
    if (!Array.isArray(elements)) elements = [elements];
    const childrenWithProps = elements.map((v, i) => {
      let options = {
        open: false,
        first: false,
        last: false,
        before: false,
        after: false,
      };
      if (i === 0) options.first = true;
      if (i === elements.length - 1) options.last = true;
      // if (!options.first && elements[i - 1].open);
      if (!v) return v;
      return React.cloneElement(v, options);
    });

    return (
      <div className="caser-collapsable-container">{childrenWithProps}</div>
    );
  }
}
export class CollapsablePanel extends React.Component {
  state = {
    isOpen: this.props.defaultIsOpen || false,
  };
  componentDidUpdate(prevProps) {
    if (!prevProps.defaultIsOpen && this.props.defaultIsOpen === true)
      this.setState({ isOpen: true });
  }
  toggle() {
    if (this.props.disableOpen || !this.props.children) return;
    this.setState({ isOpen: this.state.isOpen ? false : true });
    // this.props.open = this.state.isOpen;
  }
  render() {
    var className = "caser-collapsable-element";
    if (this.props.first) className += " first";
    if (this.props.last) className += " last";
    if (this.state.isOpen === true) className += " open";
    return (
      <div className={className}>
        <div
          disabled={this.props.disabled || false}
          onClick={this.toggle.bind(this)}
          className={"caser-collapsable-element-header"}
          {...(this.props.headerProps || {})}
        >
          {!this.props.header ? this.props.children[0] : this.props.header}
        </div>
        <div className={"caser-collapsable-element-body"}>
          {this.props.renderClosed === false && this.state.isOpen === false
            ? null
            : !this.props.header
            ? this.props.children[1]
            : this.props.children}{" "}
        </div>
      </div>
    );
  }
}
