import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import {
  Container,
  Button,
  Card,
  Typography,
  TextField,
  // Select,
  MenuItem,
  CardActions,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import Stepper from "./Stepper";

export default class CandidateInformation extends Component {
  static contextType = FeathersContext;
  state = {
    firstName: "",
    middleName: "",
    lastName: "",
    dob: null,
    nominatingPriest: "",
    nominatingPriestOther: "",
    churchFathers: [],
  };
  touched = {};
  async componentDidMount() {
    try {
      let language = await this.context.app.service("publicexam").create({
        func: "getLanguage",
        params: { examCode: this.props.examCode },
      });
      this.context.LC.setLanguage(language);
      console.log("Lang", language);
      let church = await this.context.app.service("publicexam").create({
        func: "getChurchFathers",
        params: { examCode: this.props.examCode },
      });
      this.setState({ churchFathers: church.fathers });
    } catch (e) {
      console.log(e);
    }
  }
  handleSubmitClick = async () => {
    try {
      await this.context.app.service("publicexam").create({
        func: "submitCandidateInfo",
        params: {
          id: this.props.examCode,
          data: {
            firstName: this.state.firstName,
            middleName: this.state.middleName,
            lastName: this.state.lastName,
            phoneNumber: this.state.phone,
            emailAddress: this.state.email,
            dob: moment(this.state.dob).toISOString(),
            nominatingPriest: this.state.nominatingPriest,
            nominatingPriestOther: this.state.nominatingPriestOther,
          },
        },
      });
      await this.props.goToNextPage();
    } catch (e) {
      console.log(e);
    }
  };
  // async handleArabicClick() {
  //     try {
  //         await this.context.app.service("publicexam").create({
  //             func: "setLanguage",
  //             params: { id: this.props.examCode, language: "Ar" },
  //         });
  //     } catch (e) {
  //         console.log(e);
  //     }
  // }
  validateEmail(email) {
    // eslint-disable-next-line
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  isEntryValid() {
    // if (this.state.firstName.length < 1) return false;
    // if (this.state.lastName.length < 1) return false;
    // if (!this.validateEmail(this.state.email)) return false;
    // if (typeof this.state.phone !== "string") return false;
    // if (this.state.phone.replace(/\D/g, "").length < 10) return false;

    // if (!moment(this.state.dob).isValid()) return false;
    // if (this.state.nominatingPriest.length < 1) return false;
    // if (
    //   this.state.nominatingPriest === "Other" &&
    //   this.state.nominatingPriestOther.length < 1
    // )
    if (
      this.isFirstNameValid() &&
      this.isLastNameValid() &&
      this.isEmailValid() &&
      this.isPhoneValid() &&
      this.isDOBValid() &&
      this.isNPValid() &&
      this.isNPOValid()
    )
      return true;
    return false;
  }
  isFirstNameValid() {
    if (this.state.firstName.length < 1) return false;
    return true;
  }
  isLastNameValid() {
    if (this.state.lastName.length < 1) return false;
    return true;
  }
  isEmailValid() {
    if (!this.validateEmail(this.state.email)) return false;
    return true;
  }
  isPhoneValid() {
    if (typeof this.state.phone !== "string") return false;
    if (this.state.phone.replace(/\D/g, "").length < 10) return false;
    return true;
  }
  isDOBValid() {
    if (!moment(this.state.dob).isValid()) return false;
    return true;
  }
  isNPValid() {
    if (this.state.nominatingPriest.length < 1) return false;
    return true;
  }
  isNPOValid() {
    if (
      this.state.nominatingPriest === "Other" &&
      this.state.nominatingPriestOther.length < 1
    )
      return false;
    return true;
  }
  render() {
    return (
      <div
        style={{ textAlign: "center" }}
        dir={this.state.language === "Ar" ? "rtl" : "ltr"}
      >
        <Stepper
          active={1}
          languageChooserClick={this.props.goToPreviousPage}
        />
        <Container maxWidth={"sm"} style={{ marginTop: 150 }}>
          <Card raised style={{ padding: 20 }}>
            <div style={{ textAlign: "start" }}>
              <Typography variant="h5" gutterBottom>
                {this.context.LC.val("Candidate Information")}
              </Typography>

              <Typography gutterBottom>
                {this.context.LC.language === "Ar"
                  ? this.context.LC.val(
                      "It is preferred to fill out the information in english letters"
                    )
                  : null}
              </Typography>
              <Typography variant="caption" gutterBottom>
                {this.context.LC.val("* are required fields")}
              </Typography>

              <br />

              <TextField
                error={this.touched.firstName && !this.isFirstNameValid()}
                onFocus={() => (this.touched.firstName = true)}
                label={this.context.LC.val("First Name")}
                fullWidth
                required
                value={this.state.firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
              />
              <TextField
                label={this.context.LC.val("Middle Name")}
                onFocus={() => (this.touched.middleName = true)}
                fullWidth
                value={this.state.middleName}
                onChange={(e) =>
                  this.setState({
                    middleName: e.target.value,
                  })
                }
              />
              <TextField
                label={this.context.LC.val("Last Name")}
                error={this.touched.lastName && !this.isLastNameValid()}
                onFocus={() => (this.touched.lastName = true)}
                fullWidth
                required
                value={this.state.lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
              />
              <TextField
                label={this.context.LC.val("Email Address")}
                error={this.touched.email && !this.isEmailValid()}
                onFocus={() => (this.touched.email = true)}
                fullWidth
                required
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              <TextField
                label={this.context.LC.val("Phone Number")}
                error={this.touched.phone && !this.isPhoneValid()}
                onFocus={() => (this.touched.phone = true)}
                fullWidth
                required
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
              />

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  fullWidth
                  error={this.touched.dob && !this.isDOBValid()}
                  onFocus={() => (this.touched.dob = true)}
                  openTo="year"
                  variant="inline"
                  format="MM/DD/YYYY"
                  margin="normal"
                  label={this.context.LC.val("Date of Birth (MM/DD/YYYY)")}
                  value={this.state.dob}
                  onChange={(value) => {
                    this.setState({ dob: value });
                  }}
                />
              </MuiPickersUtilsProvider>
              <TextField
                label={this.context.LC.val("Nominating Priest")}
                fullWidth
                error={this.touched.nominatingPriest && !this.isNPValid()}
                onFocus={() => (this.touched.nominatingPriest = true)}
                required
                select
                value={this.state.nominatingPriest}
                onChange={(e) =>
                  this.setState({
                    nominatingPriest: e.target.value,
                  })
                }
              >
                {this.state.churchFathers.map((v, i) => (
                  <MenuItem key={v._id} value={v._id}>
                    Fr. {v.firstName} {v.lastName}
                  </MenuItem>
                ))}
                <MenuItem value={"Other"}>
                  {this.context.LC.val("Other Nominating Priest")}
                </MenuItem>
              </TextField>
              {this.state.nominatingPriest === "Other" ? (
                <TextField
                  label={this.context.LC.val(
                    "Other Nominating Priest (Please Specify)"
                  )}
                  fullWidth
                  onFocus={() => (this.touched.nominatingPriestOther = true)}
                  error={
                    this.touched.nominatingPriestOther && !this.isNPOValid()
                  }
                  autoFocus
                  required
                  value={this.state.nominatingPriestOther}
                  onChange={(e) =>
                    this.setState({
                      nominatingPriestOther: e.target.value,
                    })
                  }
                ></TextField>
              ) : null}

              <CardActions>
                <Button
                  variant={"contained"}
                  type="submit"
                  color={"primary"}
                  disabled={!this.isEntryValid()}
                  onClick={this.handleSubmitClick}
                >
                  {this.context.LC.val("Continue")}
                </Button>
              </CardActions>
            </div>
          </Card>
        </Container>
      </div>
    );
  }
}
