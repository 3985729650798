import React from "react";

class TextBar extends React.Component {
  render() {
    var bProps = {};
    if (this.props.bodyStyle) {
      bProps = this.props.bodyStyle;
    }
    var vProps = {};
    if (this.props.style) {
      vProps = this.props.style;
    }

    var lProps = {};
    if (this.props.leftStyle) {
      lProps = this.props.leftStyle;
    }

    var rProps = {};
    if (this.props.rightStyle) {
      rProps = this.props.rightStyle;
    }

    var showAll = true;
    if (this.props.hasOwnProperty("showAll") && !this.props.showAll)
      showAll = false;
    var showMain = true;
    if (this.props.hasOwnProperty("showMain") && !this.props.showMain)
      showMain = false;
    var showLeft = true;
    if (this.props.hasOwnProperty("showLeft") && !this.props.showLeft)
      showLeft = false;
    var showRight = true;
    if (this.props.hasOwnProperty("showRight") && !this.props.showRight)
      showRight = false;
    if (this.props.hasOwnProperty("show") && !this.props.show) return null;
    return (
      <div
        className={this.props.className}
        {...this.props}
        style={Object.assign(
          {
            display: "flex",
            flexDirection: "row"
          },
          vProps
        )}
      >
        <div style={lProps}>{showLeft && showAll ? this.props.left : null}</div>
        <div
          style={Object.assign(
            {
              flexGrow: 1,
              // whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            },
            bProps
          )}
        >
          {showMain && showAll ? this.props.children : null}
        </div>
        <div
          style={Object.assign(rProps, {
            paddingRight: "10px"
          })}
        >
          {showRight && showAll ? this.props.right : null}
        </div>
      </div>
    );
  }
}

export default TextBar;
