import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import { Container, TextField, Button } from "@material-ui/core";
import CaserCard from "./../../components/mainComponents/card";
import MuiAlert from "@material-ui/lab/Alert";
class Login extends Component {
  static contextType = FeathersContext;
  state = {
    username: "",
    password: "",
    error: null,
  };
  async handleLoginClick() {
    try {
      let result = await this.props.loginFunc(this.state);
      if (result !== true) this.setState({ error: result });

      console.log(result);
    } catch (e) {
      console.log(e.message);
      this.setState({ error: e.message });
    }
  }

  render() {
    return (
      <div>
        <Container maxWidth={"xs"} style={{ marginTop: 150 }}>
          <CaserCard header={"Login to Subdeacon Exam Admin"}>
            {this.state.error ? (
              <MuiAlert severity="error">{this.state.error}</MuiAlert>
            ) : null}
            <TextField
              hintText="Enter your Email"
              fullWidth
              label="Email"
              onChange={(event) =>
                this.setState({ username: event.target.value.toLowerCase() })
              }
              value={this.state.username}
            />
            <br />
            <TextField
              type="password"
              fullWidth
              hintText="Enter your Password"
              label="Password"
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.handleLoginClick();
                }
              }}
              value={this.state.password}
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={(event) => this.handleLoginClick(event)}
            >
              Submit
            </Button>
          </CaserCard>
        </Container>
      </div>
    );
  }
}

export default Login;
