import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import {
  Container,
  // TextField,
  Button,
  // Card,
  CardActions,
  Typography,
  Divider,
  Box,
} from "@material-ui/core";
import Card from "./../../components/mainComponents/card";
import moment from "moment-timezone";
import Contact from "./Contact";
class ExamPublic extends Component {
  static contextType = FeathersContext;
  constructor(props) {
    super(props);
    this.state = {
      upcomingExams: [],
      isLive: false,
    };
  }
  async componentDidMount() {
    let upcomingExams = await this.context.app.service("publicexam").create({
      func: "getUpcomingExams",
    });
    this.setState({ upcomingExams: upcomingExams });
  }
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Container maxWidth={"md"} style={{ marginTop: 150 }}>
          <Card header={"Upcoming Exam Schedule"}>
            {this.state.upcomingExams.map((v, i) => (
              <div key={v._id}>
                <Typography variant="h6">{v.title}</Typography>
                {v.tws.map((tw) => (
                  <Typography key={tw._id}>
                    {moment(tw.startDateTime)
                      .tz("America/New_York")
                      .format("ll h a z")}
                    {" to "}
                    {moment(tw.endDateTime)
                      .tz("America/New_York")
                      .format("ll h a z")}
                    <br />
                  </Typography>
                ))}
                <br />
                <Divider />
                <br />
              </div>
            ))}
            <CardActions>
              <Box flexGrow={1} />
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => this.props.history.push("/exam/login")}
              >
                Start Exam Now
              </Button>
              <Box flexGrow={1} />
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => this.props.history.push("/exam/login")}
              >
                {this.context.LC.val("Start Exam Now", "Ar")}
              </Button>
              <Box flexGrow={1} />
            </CardActions>
          </Card>
          <Card header={"Deacon Ranks Ordination Requirements"}>
            <Button
              variant={"contained"}
              color={"primary"}
              href={"/resources/1_psaltis_ordination_requirements.pdf"}
              target={"_blank"}
            >
              Chanters
            </Button>
            <br />
            <br />{" "}
            <Button
              variant={"contained"}
              color={"primary"}
              href={"/resources/2_anaghnostis_ordination_requirements.pdf"}
              target={"_blank"}
            >
              Readers
            </Button>
            <br />
            <br />
            <Button
              variant={"contained"}
              color={"primary"}
              href={"/resources/3_epideacon_ordination_requirements.pdf"}
              target={"_blank"}
            >
              Sub-Deacons
            </Button>
          </Card>

          <Card header={"Diocese Subdeacon Exam Information"}>
            <Typography variant="h6" gutterBottom>
              Exam Purpose
            </Typography>
            <Typography
              variantMapping={{ body1: "div" }}
              variant="body1"
              gutterBottom
              align={"left"}
            >
              Serving in the diaconate and in the church in general is a
              responsibility that requires preparation. This is why the diocese
              is requiring an exam in which candidates need to prepare and study
              to show their proficiency in knowledge and understanding both of:
              <ol>
                <li>What they will do in their service,</li>
                <li>
                  The significance and history of the Eucharistic Liturgy in
                  which the diaconate ranks serve.
                </li>
              </ol>
            </Typography>
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              Exam Format
            </Typography>
            <Typography
              variantMapping={{ body1: "div" }}
              variant="body1"
              gutterBottom
              align={"left"}
            >
              <ul>
                <li>The exam can be taken in English or Arabic.</li>
                <li>
                  The exam is closed book. No book, study guide, or other
                  resource is permitted to be used.
                </li>
                <li>
                  The exam will allow up to 60 Minutes to complete 50 multiple
                  choice and true or false questions.
                </li>
                <li>
                  Each exam will not be identical, but will be randomly chosen
                  from a pool of questions.
                </li>
                <li>
                  The exam will be administered electronically. Candidates will
                  receive an email with a link to the exam.
                </li>
                <li>
                  The candidate must answer at least 80% of the questions
                  correctly to pass.
                </li>
                <li>
                  If the candidate does not pass the exam, he will be permitted
                  to retake the exam up to two additional times. After the 3rd
                  opportunity, additional requirements may be necessary prior to
                  ordination.
                </li>
              </ul>
            </Typography>
            <br />
            <br />
            <Typography variant="h6" gutterBottom>
              Exam Content
            </Typography>

            <Typography
              variantMapping={{ body1: "div" }}
              variant="body1"
              gutterBottom
              align={"left"}
            >
              The exam will contain questions from two subjects.
              <ol>
                <li>
                  <b>Primarily: Specific questions</b> about the significance
                  and history of the Eucharistic liturgy will come from the book
                  Christ in the Eucharist by Fr. Tadros Malaty. For example:{" "}
                  <ol>
                    <li>
                      Questions on proper names of and sequence of liturgical
                      sections, prayers, and subjects
                    </li>
                    <li>
                      Questions on significant names, dates, meanings, sources
                      and/or origins in liturgical history
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Other General Questions:</b> For example:
                  <ol>
                    <li>
                      Questions that the common faithful should be able to
                      answer, such as, which prayers or rites are used on which
                      days;{" "}
                    </li>
                    <li>
                      Questions about serving in the altar, such as, when, how,
                      or where responses or actions are to be done in the altar
                      service.
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
          </Card>
          <Card header={"Exam Study Resources"}>
            <Typography variant="h6">
              Christ in the Eucharist - Fr. Tadros Y. Malaty
            </Typography>
            <div
              style={{
                width: "inherit",
                display: "flex",
                flexDirection: "row",
                padding: "10px",
              }}
            >
              <Box flexGrow={1} />

              <Button
                variant={"contained"}
                color={"primary"}
                href={
                  "/resources/Christ in the Eucharist - Fr Tadros Malaty.pdf"
                }
                target={"_blank"}
              >
                English
              </Button>
              <Box flexGrow={1} />
              <Button
                variant={"contained"}
                color={"primary"}
                href={
                  "/resources/Christ in the Eucharist - Fr Tadros Malaty-Arabic.pdf"
                }
                target={"_blank"}
              >
                Arabic
              </Button>
              <Box flexGrow={1} />
            </div>
            <Divider />
            <Typography variant="h6">Exam Study Guide</Typography>
            <div
              style={{
                width: "inherit",
                display: "flex",
                flexDirection: "row",
                padding: "10px",
              }}
            >
              <Box flexGrow={1} />

              <Button
                variant={"contained"}
                color={"primary"}
                href={"/resources/Subdeacon exam study guide.pdf"}
                target={"_blank"}
              >
                English
              </Button>
              <Box flexGrow={1} />
              <Button
                variant={"contained"}
                color={"primary"}
                href={"/resources/المرشد الدراسى لمساعد الشماس .pdf"}
                target={"_blank"}
              >
                Arabic
              </Button>
              <Box flexGrow={1} />
            </div>
            <Divider />
          </Card>
          <Contact />
          <br />
          <br />
          <br />
        </Container>
      </div>
    );
  }
}

export default ExamPublic;
