import React, { Component } from "react";
import FeathersContext from "../../feathersContext";
import { Container, Button, Card, Typography } from "@material-ui/core";
export default class extends Component {
  static contextType = FeathersContext;
  state = {};
  async componentDidMount() {
    let language = await this.context.app.service("publicexam").create({
      func: "getLanguage",
      params: { examCode: this.props.examCode },
    });
    this.context.LC.setLanguage(language);
    this.setState({ language });
  }
  async handleCertifyClick() {
    try {
      await this.context.app.service("publicexam").create({
        func: "setCertification",
        params: { id: this.props.examCode, certification: true },
      });
      await this.props.goToNextPage();
    } catch (e) {
      console.log(e);
    }
  }
  async handleNotCertifyClick() {
    try {
      await this.context.app.service("publicexam").create({
        func: "setCertification",
        params: { id: this.props.examCode, certification: false },
      });
      await this.props.goToNextPage();
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div
        style={{ textAlign: "center" }}
        dir={this.state.language === "Ar" ? "rtl" : "ltr"}
      >
        <Container maxWidth={"sm"} style={{ marginTop: 150 }}>
          <Card raised style={{ padding: 20 }}>
            <Typography
              variant="h5"
              gutterBottom
              align={this.state.language === "Ar" ? "right" : "left"}
            >
              {this.context.LC.val(
                "Please answer the following certification:"
              )}
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              align={this.state.language === "Ar" ? "right" : "left"}
            >
              {this.context.LC.val(
                "I certify that I took this exam without any assistance from materials (books, study guides, apps, websites, etc.) and without participation, advice, or assistance from others."
              )}
            </Typography>
            <br />
            <Typography
              variant="h6"
              gutterBottom
              align={this.state.language === "Ar" ? "right" : "left"}
            >
              {this.context.LC.val(
                "Saving exam questions for yourself or others by writing them, taking pictures, or any other means is strictly forbidden for both those who do so, those who distribute them, and those who receive such materials. This is cheating and not befitting of a Christian, let alone a servant or rank of the diaconate. We commend all those who have honestly and faithfully taken this exam and we warn all those that have deceitfully taken this exam that the Lord sees and knows what no one else does."
              )}
            </Typography>

            <br />
            <div
              style={{ display: "flex", flexDirection: "row" }}
              align="start"
            >
              <div style={{ flexGrow: 1 }}>
                <Button
                  onClick={this.handleCertifyClick.bind(this)}
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  {this.context.LC.val("I certify")}
                </Button>
              </div>
              <div style={{ flexGrow: 1 }}>
                <Button
                  onClick={this.handleNotCertifyClick.bind(this)}
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  {this.context.LC.val("I do not certify")}
                </Button>
              </div>
            </div>
          </Card>
        </Container>
      </div>
    );
  }
}
