import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import LeftMenuLayout from "../../components/left-menu-detail";
import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  Button,
  TextField,
  Container,
  Card,
  CardActions,
  Select,
  MenuItem,
  List,
  ListItem,
  InputLabel,
} from "@material-ui/core";
import FeathersContext from "../../feathersContext";
export default class Candidates extends React.Component {
  static contextType = FeathersContext;
  state = {
    candidates: [],
    churches: {},
    activeCandidate: null,
  };
  async componentDidMount() {
    await this.getChurches();
    this.setState({
      activeCandidate: this.props.match.url.split("/")[3] || null,
    });
    await this.getCandidates();
    this.context.app.service("candidates").on("patched", this.updateOnPatched);
    this.context.app.service("candidates").on("removed", this.updateOnRemoved);
    this.context.app.service("churches").on("all", this.getChurches.bind(this));
  }
  async getChurches() {
    try {
      let list = await this.context.app.service("churches").find();

      this.setState((state) => {
        for (let churchIndex in list.data) {
          let church = list.data[churchIndex];
          state.churches[church._id] =
            church.churchName +
            " " +
            church.churchCity +
            ", " +
            church.churchState;
        }
        return state;
      });
      console.log(this.state.churches);
    } catch (e) {}
  }

  updateOnPatched = (message) =>
    this.setState((state) => {
      state.candidates[this.getCandidateIndex(message._id)] = message;
      return state;
    });

  updateOnRemoved = (message) =>
    this.setState((state) => {
      let index = this.getCandidateIndex(message._id);
      state.candidates.splice(index, 1);
      let id = null;
      if (state.candidates[index]) id = state.candidates[index]._id;
      else if (state.candidates[index - 1])
        id = state.candidates[index - 1]._id;
      else if (state.candidates[index + 1])
        id = state.candidates[index + 1]._id;
      this.setActiveCandidate(id);
      return state;
    });

  async componentWillUnmount() {
    this.context.app.service("candidates").off("patched", this.updateOnPatched);
    this.context.app.service("candidates").off("removed", this.updateOnRemoved);
    this.context.app
      .service("churches")
      .off("all", this.getChurches.bind(this));
  }
  async getCandidates() {
    try {
      let list = await this.context.app.service("candidates").find();
      this.setState({ candidates: list.data });
      console.log(list);
      if (this.state.candidates.length && this.state.activeCandidate === null)
        this.setActiveCandidate(this.state.candidates[0]._id);
    } catch (e) {}
  }
  async addCandidate() {
    try {
      let newCandidate = await this.context.app
        .service("candidates")
        .create({});
      console.log("Add", newCandidate);

      await this.getCandidates();
      this.setActiveCandidate(newCandidate._id);
    } catch (e) {}
  }
  setActiveCandidate(id) {
    this.setState({ activeCandidate: id });
    let url = this.props.match.url.split("/");
    url[3] = id;
    this.props.history.replace(url.join("/"));
  }
  getCandidateIndex(id = this.state.activeCandidate) {
    for (let [index, candidate] of this.state.candidates.entries()) {
      if (candidate._id === id) {
        return index;
      }
    }
    return -1;
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 0,
            background: "#546E7A",
            color: "#fff",
            padding: 10,
          }}
        >
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {this.anchor || ""} Candidates
          </Typography>
          <Button
            color="inherit"
            onClick={this.addCandidate.bind(this)}
            style={{ flexGrow: 0, fontWeight: "bold" }}
          >
            New Candidate
          </Button>
        </div>
        <LeftMenuLayout
          leftDrawer={
            <div
              style={{
                flexGrow: 0,
                minWidth: "20%",
                background: "#ECEFF1",
                overflowY: "scroll",
              }}
            >
              <List>
                {this.state.candidates.map((v) => (
                  <ListItem
                    button
                    style={{
                      fontWeight: 600,
                      padding: "15px 10px",
                      background:
                        this.state.activeCandidate === v._id ? "#B0BEC5" : "",
                    }}
                    onClick={() => this.setActiveCandidate(v._id)}
                    key={v._id}
                  >
                    {v.lastName}, {v.firstName}
                    <br /> &nbsp;&nbsp;
                    {this.state.churches[v.churchId]}
                  </ListItem>
                ))}
              </List>
            </div>
          }
          mainContent={(anchor) => {
            this.anchor = anchor;
            return (
              <div
                style={{
                  flexGrow: 3,
                  background: "#CFD8DC",
                  overflowY: "scroll",
                  paddingBottom: 30,
                }}
              >
                {this.state.churches ? (
                  <CandidateDetails
                    churches={this.state.churches}
                    parent={this}
                    id={this.state.activeCandidate}
                  />
                ) : null}
              </div>
            );
          }}
        />
      </div>
    );
  }
}

class CandidateDetails extends React.Component {
  static contextType = FeathersContext;

  state = { data: null, fathers: [], deleteDialogOpen: false };
  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.getCandidate();
    }
  }
  async getCandidate() {
    if (this.props.id === null) {
      return;
    }
    this.setState({ data: null });
    try {
      let data = await this.context.app
        .service("candidates")
        .get(this.props.id);
      this.setState({ data });
      await this.getCandidateFathers();
    } catch (e) {
      console.log(e);
    }
  }

  updateField(fieldName, value) {
    if (this.props.id === null) {
      return;
    }
    try {
      this.setState((state) => {
        state.data[fieldName] = value;
        return state;
      });
      let data = {
        [fieldName]: value,
      };
      if (data[fieldName].trim) data[fieldName] = data[fieldName].trim();
      this.updateFieldServer(this.state.data._id, data);
    } catch (e) {
      console.log(e);
    }
  }
  async updateFieldServer(id, data) {
    await this.context.app.service("candidates").patch(id, data);
  }
  async removeCandidate() {
    if (this.props.id === null) {
      return;
    }
    try {
      await this.context.app.service("candidates").remove(this.props.id);

      this.setState({ deleteDialogOpen: false });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    if (this.props.id === null)
      return <div>Select a Candidate to edit the details.</div>;
    else if (this.state.data === null) {
      return null;
    } else {
      return (
        <div>
          <Container maxWidth="sm">
            <br />
            <br />
            <Card raised style={{ padding: "20px", textAlign: "center" }}>
              <Typography variant="h6">Candidate Details</Typography>
              <TextField
                fullWidth
                label="First Name"
                value={this.state.data.firstName}
                onChange={(e) => {
                  this.updateField("firstName", e.target.value);
                }}
              />{" "}
              <br /> <br />
              <TextField
                fullWidth
                label="Last Name"
                value={this.state.data.lastName}
                onChange={(e) => {
                  this.updateField("lastName", e.target.value);
                }}
              />{" "}
              <br />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  fullWidth
                  autoOk
                  format="M/D/YYYY"
                  margin="normal"
                  label="Date of Birth"
                  value={this.state.data.dob}
                  onChange={(value) => {
                    this.updateField("dob", value);
                  }}
                />
              </MuiPickersUtilsProvider>
              <TextField
                fullWidth
                label="Email Address"
                type="email"
                pattern="[^ @]*@[^ @]*"
                value={this.state.data.emailAddress}
                onChange={(e) => {
                  this.updateField("emailAddress", e.target.value);
                }}
              />
              <br />
              <TextField
                fullWidth
                label="Phone Number"
                type="phone"
                value={this.state.data.phoneNumber}
                onChange={(e) => {
                  this.updateField("phoneNumber", e.target.value);
                }}
              />
              <br />
              <br />
              <div style={{ textAlign: "left" }}>
                <InputLabel style={{ textAlign: "left" }} id="ChurchLabel">
                  Church
                </InputLabel>

                <Select
                  labelId="ChurchLabel"
                  fullWidth
                  value={this.state.data.churchId}
                  onChange={(e) => {
                    this.updateField("churchId", e.target.value);
                  }}
                >
                  {Object.keys(this.props.churches).length ? (
                    Object.keys(this.props.churches).map((v) => (
                      <MenuItem key={v} value={v}>
                        {this.props.churches[v]}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value={"-1"}>Loading Churches...</MenuItem>
                  )}
                </Select>
                <Typography variant="caption">
                  Database ID: {this.state.data._id}
                </Typography>
              </div>
              <CardActions>
                <Button
                  color="secondary"
                  onClick={() =>
                    this.setState({
                      deleteDialogOpen: true,
                    })
                  }
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
            <br />
            <br />
            {/* <Card raised style={{ padding: "20px", textAlign: "center" }}>
              <Typography variant="h6">Candidate Fathers</Typography><br /><br /><br />
              {this.state.fathers.map(v => (
                <FatherDetails parent={this} id={v._id} key={v._id} />
              ))}
              <CardActions>
                <Button color="primary" onClick={this.addFather.bind(this)}>
                  New Father
                </Button>
              </CardActions>
            </Card> */}
          </Container>
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            open={this.state.deleteDialogOpen}
          >
            <DialogTitle id="confirmation-dialog-title">
              Are you sure?
            </DialogTitle>
            <DialogContent>
              Are you sure you want to permenantly delete this candidate from
              the database?
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => this.setState({ deleteDialogOpen: false })}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={this.removeCandidate.bind(this)} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  }
}
