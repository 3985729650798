import React, { Component } from "react";
import FeathersContext from "../../../feathersContext";
import {
  Container,
  Button,
  Card,
  CardActions,
  LinearProgress,
  Grid,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// import MCQuestion from "./MCQuestion";
// import TFQuestion from "./TFQuestion";
import moment from "moment";
export default class extends Component {
  constructor(props) {
    super(props);
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "a few seconds",
        ss: "%d seconds",
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        w: "a week",
        ww: "%d weeks",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years",
      },
    });
  }
  static contextType = FeathersContext;
  state = {
    currentTime: null,
    ExamInfo: {},
    onQuestion: 0,
    questions: {},
    completeDialogOpen: false,
    networkDialogOpen: false,
  };
  timeUpdater = null;
  timeSyncer = null;
  async componentDidMount() {
    let ExamInfo = await this.context.app.service("publicexam").create({
      func: "getExamInfo",
      params: { examCode: this.props.examCode },
    });
    console.log(ExamInfo);
    this.context.LC.setLanguage(ExamInfo.language);
    this.setState({
      ...ExamInfo,
      startTime: moment(ExamInfo.examStartTime),
      endTime: moment(ExamInfo.examEndTime),
    });
    // console.log(
    //   this.state.ExamInfo.questionText[
    //     Object.keys(this.state.ExamInfo.questions[this.state.onQuestion - 1])[0]
    //   ]
    // );
    this.syncTime();
    this.timeSyncer = setInterval(this.syncTime, 60000);
    this.checkNetwork = setInterval(() => {
      if (this.context.networkConnected === false)
        this.setState({ networkDialogOpen: true });
      else this.setState({ networkDialogOpen: false });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timeUpdater) clearInterval(this.timeUpdater);
    if (this.timeSyncer) clearInterval(this.timeSyncer);
  }
  syncTime = async () => {
    try {
      console.log(
        await this.context.app.service("publicexam").create({
          func: "getTime",
          params: { examCode: this.props.examCode },
        })
      );
      let currentTime = moment(
        await this.context.app.service("publicexam").create({
          func: "getTime",
        })
      );
      this.setState({ currentTime });
      if (this.timeUpdater) clearInterval(this.timeUpdater);
      this.timeUpdater = setInterval(this.addSecondAndCheckTime, 1000);
    } catch (e) {}
  };
  addSecondAndCheckTime = async () => {
    let currentTime = this.state.currentTime.add(1, "seconds");
    let secondsFromStart = moment.duration(
      currentTime.diff(this.state.startTime)
    );
    let secondsToEnd = moment.duration(
      this.state.endTime.diff(moment(currentTime).add(-1, "seconds"))
    );
    if (secondsToEnd.asSeconds() < 1) this.handleCompleteClick();
    this.setState({
      currentTime: currentTime,
      secondsFromStart,
      secondsToEnd,
    });
  };
  nextQuestion = () => {
    if (this.state.onQuestion >= 50) return;
    let newQ = this.state.onQuestion + 1;
    this.setState({ onQuestion: newQ });
    this.context.app.service("publicexam").create({
      func: "setQuestion",
      params: { examCode: this.props.examCode, onQuestion: newQ },
    });
  };
  prevQuestion = () => {
    if (this.state.onQuestion <= 1) return;
    let newQ = this.state.onQuestion - 1;
    this.setState({ onQuestion: newQ });
    this.context.app.service("publicexam").create({
      func: "setQuestion",
      params: { examCode: this.props.examCode, onQuestion: newQ },
    });
  };
  toggleSelectAnswer = (Question, Choice) => {
    this.setState((s) => {
      if (s.questions[Question].selectedAnswer === Choice)
        s.questions[Question].selectedAnswer = undefined;
      else s.questions[Question].selectedAnswer = Choice;
      return s;
    });
    this.updateAnswers();
  };
  updateAnswers = () => {
    this.context.app.service("publicexam").create({
      func: "updateAnswers",
      params: {
        examCode: this.props.examCode,
        questions: this.state.questions,
      },
    });
  };

  async handleCompleteClick() {
    try {
      await this.context.app.service("publicexam").create({
        func: "endExam",
        params: { id: this.state._id },
      });
      await this.props.goToNextPage();
    } catch (e) {
      console.log(e);
    }
  }
  indexToLetter(i) {
    let index = "ABCDEFGHIJKLMNOP";
    return index[i];
  }
  durationToText(duration) {
    var formattedNumber = (myNumber) => ("0" + myNumber).slice(-2);

    if (!duration || !duration.isValid) return "";
    let TArray = [];
    if (duration._data.years) TArray.push(duration._data.years + " years ");
    if (duration._data.months)
      TArray.push(
        (TArray.length
          ? Math.abs(duration._data.months)
          : duration._data.months) + " months "
      );
    if (duration._data.days)
      TArray.push(
        (TArray.length ? Math.abs(duration._data.days) : duration._data.days) +
          " days "
      );
    if (duration._data.hours)
      TArray.push(
        (TArray.length
          ? Math.abs(duration._data.hours)
          : duration._data.hours) + ":"
      );
    if (duration._data.minutes)
      TArray.push(
        formattedNumber(
          TArray.length
            ? Math.abs(duration._data.minutes)
            : duration._data.minutes
        ) + ":"
      );
    else TArray.push("00:");
    if (duration._data.seconds)
      TArray.push(
        formattedNumber(
          TArray.length
            ? Math.abs(duration._data.seconds)
            : duration._data.seconds
        )
      );
    else TArray.push("00");
    return TArray.join("").trim();
  }
  render() {
    return (
      <div
        style={{ textAlign: "center" }}
        dir={this.state.language === "Ar" ? "rtl" : "ltr"}
      >
        {this.state.questions &&
        this.state.startTime &&
        this.state.currentTime &&
        this.state.secondsFromStart ? (
          <>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <p
                  style={{
                    textAlign: "start",
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontWeight: 800,
                  }}
                >
                  {this.context.LC.val("Start")}:{" "}
                  {this.durationToText(this.state.secondsFromStart)} (
                  {this.state.startTime.format("M/D/YY hh:mm:ss")})
                </p>
              </Grid>
              <Grid item xs={6}>
                <p
                  style={{
                    textAlign: "end",
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontWeight: 800,
                  }}
                >
                  {this.context.LC.val("End")}:{" "}
                  {this.durationToText(this.state.secondsToEnd)} (
                  {this.state.endTime.format("M/D/YY hh:mm:ss")})
                </p>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <LinearProgress
                  variant="determinate"
                  value={
                    (this.state.secondsFromStart.asSeconds() /
                      this.state.endTime.diff(
                        this.state.startTime,
                        "seconds"
                      )) *
                    100
                  }
                  color={
                    this.state.secondsFromStart.asSeconds() /
                      this.state.endTime.diff(this.state.startTime, "seconds") <
                    0.95
                      ? "primary"
                      : "secondary"
                  }
                />
              </Grid>
            </Grid>

            <Container maxWidth={"md"} style={{ marginTop: 50 }}>
              {this.state.networkDialogOpen ? (
                <Alert severity="error">
                  No Network Connection. Please reconnect to the internet to
                  save the progress of your exam or submit your exam to be
                  scored. Do not refresh the page, you will be reconnected once
                  the internet is available.
                </Alert>
              ) : null}

              <Card variant="outlined" style={{ padding: 20 }}>
                <CardActions>
                  {this.state.onQuestion > 1 ? (
                    <Button
                      onClick={this.prevQuestion}
                      size="small"
                      color="primary"
                      variant="outlined"
                    >
                      {this.context.LC.val("Previous Question")}
                    </Button>
                  ) : null}
                  <Box flexGrow={1} />

                  {this.state.onQuestion !== 50 ? (
                    <Button
                      onClick={this.nextQuestion}
                      disabled={
                        !this.state.questions[this.state.onQuestion]
                          .selectedAnswer
                      }
                      size="small"
                      color="primary"
                      variant="contained"
                    >
                      {this.context.LC.val("Next Question")}
                    </Button>
                  ) : null}
                  {this.state.onQuestion === 50 ? (
                    <Button
                      onClick={() =>
                        this.setState({ completeDialogOpen: true })
                      }
                      disabled={
                        !this.state.questions[this.state.onQuestion]
                          .selectedAnswer || this.state.networkDialogOpen
                      }
                      size="small"
                      color="primary"
                      variant="contained"
                    >
                      {this.context.LC.val("Complete Exam")}
                    </Button>
                  ) : null}
                  {/* <Button
                    // onClick={this.handleCompleteClick.bind(this)}
                    size="small"
                    color="primary"
                    variant="contained"
                  >
                    {this.context.LC.val("Go to question")}
                  </Button> */}
                </CardActions>
                <Divider />
                <br />

                {this.state.questions &&
                this.state.onQuestion > 0 &&
                this.state.onQuestion < 51 ? (
                  <>
                    <Typography
                      gutterBottom
                      style={{ fontWeight: "800", textAlign: "start" }}
                    >
                      {this.context.LC.val("Question")}{" "}
                      {this.context.LC.val(this.state.onQuestion.toString())}
                    </Typography>
                    <p
                      variant="h6"
                      style={{
                        textAlign: "start",
                        fontSize: "120%",
                        fontWeight: 800,
                      }}
                    >
                      {
                        this.state.questionText[
                          this.state.questions[this.state.onQuestion].id
                        ].Question[this.state.language]
                      }
                    </p>

                    {this.state.questions[this.state.onQuestion].choices.map(
                      (v, i) => (
                        <Box
                          key={v}
                          onClick={() =>
                            this.toggleSelectAnswer(this.state.onQuestion, v)
                          }
                          style={{
                            fontWeight: "800",
                            textAlign: "start",
                            margin: "0px",
                            padding: "20px",
                            cursor: "pointer",
                            width: "inherit",
                            borderRadius: "5px",
                            background:
                              this.state.questions[this.state.onQuestion]
                                .selectedAnswer === v
                                ? "#ccc"
                                : "#fff",
                          }}
                        >
                          {this.indexToLetter(i)}
                          {": "}
                          {
                            this.state.questionText[
                              this.state.questions[this.state.onQuestion].id
                            ].Choices[this.state.language][v]
                          }
                        </Box>
                      )
                    )}
                  </>
                ) : null}
              </Card>
              <br />
            </Container>
          </>
        ) : (
          <Backdrop open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          dir={this.state.language === "Ar" ? "rtl" : "ltr"}
          open={this.state.completeDialogOpen}
        >
          <DialogTitle id="confirmation-dialog-title">
            {this.context.LC.val("Are you sure?")}
          </DialogTitle>
          <DialogContent>
            {this.context.LC.val(
              "Are you sure you want to complete this exam?"
            )}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => this.setState({ completeDialogOpen: false })}
              color="primary"
              variant="outlined"
            >
              {this.context.LC.val("No")}
            </Button>
            <Button
              onClick={this.handleCompleteClick.bind(this)}
              color="primary"
              variant="outlined"
            >
              {this.context.LC.val("Yes")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
