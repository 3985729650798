import React,{Component} from "react"
import Login from "../pages/login/Login"

import FeathersContext from "../feathersContext"
export default class Auth extends Component {
    static contextType = FeathersContext;
constructor(props){
  super(props);
  this.state={
  authenticated:null
  }
 }
 componentDidMount() {
  this.checkAuthentication();
  this.context.app.authentication.service.on('removed', this.checkAuthentication);
}
async logout() {
  await this.context.app.logout();
  await this.checkAuthentication();

}
async login(data) {
    var payload={
        "strategy":"local",
    "email":data.username,
    "password":data.password
    }
    console.log(this.context,payload)
    let result;
    try {
        await this.context.app.authenticate(payload)
        console.log(result);
        result = true;
        await this.checkAuthentication();

    } catch(e) {
      result = e.message;
        this.setState({error:e.message})

    }
    return result;
}
 async checkAuthentication() {
    console.log("checking auth");
  try {
      this.context.app.reAuthenticate().then((r)=>{
        this.setState({authenticated:true})

      }).catch((e)=>{
        this.setState({authenticated:false})

      });

    } catch (e) {
      // console.log(e)

    }
  }

render() {
if (this.state.authenticated === null) return null;
else if (this.state.authenticated === true) return (<>
{React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        logoutFunc: this.logout.bind(this)
      });
    })}
</>) 
else return (
     <Login loginFunc={this.login.bind(this)} />
    );
  }
}
